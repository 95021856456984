
@font-face {
  font-family: OpenSans;
  src: url('./fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: OpenSansbold;
  src: url('./fonts/OpenSans-Bold.ttf');
}
@font-face {
  font-family: AvenirLTStd-Book;
  src: url('./fonts/AvenirLTStd-Book.ttf');
}
@font-face {
  font-family: AvenirLTStd-Heavy;
  src: url('./fonts/AvenirLTStd-Heavy.ttf');
}
@font-face {
  font-family: HelveticaNeue;
  src: url('./fonts/HelveticaNeue.ttf');
}
@font-face {
  font-family: HelveticaNeueBold;
  src: url('./fonts/HelveticaNeuBold.ttf');
}
@font-face {
  font-family: Arial;
  src: url('./fonts/Arialn.ttf');
}
@font-face {
  font-family: OpenSanssemibold;
  src: url('./fonts/OpenSans-SemiBold.ttf');
}
@font-face {
  font-family: OpenSansextrabold;
  src: url('./fonts/OpenSans-ExtraBold.ttf');
}

@font-face {
  font-family: century-gothik-regular,"HelveticaNeue","Arial",sans-serif;
  src: url('./fonts/CenturyGothicRegular.ttf');
}

@font-face {
  font-family: CenturyBold;
  src: url('./fonts/CenturyGothicBold.ttf');
}

@font-face {
  font-family: circular-bold;
  src: url('./fonts/Circular_Std_Bold.ttf');
}

@font-face {
  font-family: circular-regular;
  src: url('./fonts/Circular_Std_Book.ttf');
}

body{
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
  background-color: #fff !important;
  margin-top: 0px !important;
  min-height: 0 !important;
  height: 100vh;
  position: relative;
}
.switch {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 34px;
}
.trymethod{
  flex-direction: row !important;
  justify-content: space-around !important;
}
.trymethod_button{
  background: transparent;
  color: #000;
  border: 2px solid #ddd;
}
.trymethod button{
    margin: 0px !important;
}
.switch input {display:none;}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ca2222;
  -webkit-transition: .4s;
  transition: .4s;
   border-radius: 34px;
}


 .tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}
 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
.knowledge , .faq_button{
  position: relative;
}
.knowledge:hover .tooltiptext , .faq_button:hover .faq_tip{
  visibility: visible;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #3985c1;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3985c1;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(55px);
}

/*------ ADDED CSS ---------*/
.slider:after
{
 
 color: white;
 display: block;
 position: absolute;
 transform: translate(-50%,-50%);
 top: 50%;
 left: 50%;
 font-size: 10px;
 font-family: Verdana, sans-serif;
}

 

/*--------- END --------*/
.errorMsg {
  color: #cc0000;
  float: left;
  width: 100%;
  margin: 5px 0px 5px 0px;
}
.p-0 {
    margin-top: 2.5rem !important;
}
.p-0false{
    margin-top: 2.5rem !important;
}
button.btn.btn-outline-secondary.my-4.d-flex.justify-content-between.gsingup{
  width: 100%;
  margin: 0 auto;
  justify-content: center !important;
}
button.btn.btn-outline-secondary.my-4.d-flex.justify-content-between.gsingup span{
    padding-left: 10px;
    color: #000;
}
.favicon img{
  width: 30px;
}
.sucessMsg {
  color: #6B8E23;
  margin-bottom: 10px;
}
.slick-prev:before , .slick-next:before{
  opacity: 1 !important;
  color: #143059 !important;
}
img {
  image-rendering: auto;
  max-width: 100%;
}
.ml-0{
  margin:0px;
}
a{
  text-decoration: none !important;
}
/* a:hover {
  color:#321fdb !important;
} */
.pd-0{
  padding: 0px !important;
}
.pds-0{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.member_img img{
  width: 100%;
}
.navbar-light .navbar-nav .nav-link{
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
      color: #000 !important;
}
/* .banner_title{ */
  /* width: 80%;
  margin: 0 auto; */
/* } */

.text-center{
  text-align: center !important;
  margin-top: 140px;
  margin-right: 100px;
}

.banner h3 {
    font-family: circular-regular,sans-serif;
    margin: 0 0 50px;
    color: #fff;
    width: 100%;
    font-size: 22.5px;
    text-align: center;
        margin-top: -19px;
    }
.banner h1{
  font-family: circular-bold,sans-serif;
  margin: 50px 0px 25px;
  color: #fff;
  width: 100%;
  font-size: 44px;
  text-align: center;
  text-transform: capitalize;
}
button.btn.btn-success{
      border-radius: 40px;
    width: auto;
    padding: 10px 20px;
    display: inline-block;
    font-size: 20px;
    font-family: circular-regular,sans-serif;
    background: #01b170;
    text-transform: capitalize;
}
.cnt-success {
  background: #01b170;
  font-size: 16px;
  padding: 10px;
  text-align: center;
  color: #fff;
}
.cnt-error {
  background: #dc3545;
  font-size: 16px;
  padding: 10px;
  text-align: center;
  color: #fff;
}
.hpopup-message {
color: #fff;
    text-align: center;
    padding-top: 10px;
  }
  .course_button{
  background-color: #01b170;
    border-radius: 5px !important;
    /*height: 50px;*/
    position: relative;
  }
.seo_content h6 {
  /*text-align: center; */
  max-width: 85%;
  line-height: 30px;
  font-size: 20px;
  color: #000;
  /* margin: 0 auto 50px; */
  font-family: circular-regular,sans-serif;
}
.partners ul{
  list-style: none;
  display: flex;
  justify-content: space-between;
  /* flex-flow: row; */
  width: 100%;
  margin: 50px 0px;
  padding: 0;
  margin-bottom: 0px;
}
.banner_counts ul:before{
    content: '';
  border: 6px solid #ffffff30;
  width: 100%;
  position: absolute;
  top: 50%;  
}
a.btn.btn-success.login_set {
  border-radius: 100px;
  padding: 10px 0px;
  background-color: #293e69 !important;
  border-color: #293e69 !important;
}
.login_out{
  min-width: 100px;
}
.bottome_set{
  /*background-image: url('./assets/new_banner/bottom-arc.png');*/
  position: absolute;
  width: 100%;
  height: 225px;
  background-position: left;
  background-repeat: no-repeat;
  bottom: -53px;
  z-index: 1;
  left: 0px;
  background-size: cover;
}
.mn_set{
  position: absolute !important;
  left: 0px;
  bottom: 80px;
  top: auto;
}
.img_mbanner {
    margin-bottom: 10px;
}
.resend_otp{
  float: left;
  /* margin-left: 110px; */
  /* display: none; */
}
.resend_otp span{
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
}
.resend_otp span span{
  display: none;
 
}
.resend_otp div button{
    background-color: rgb(20, 48, 89)    !important;
    border-radius: 4px !important;
    border: 2px rgb(20, 48, 89) solid !important;
    padding: 4px 10px !important;
     color: #fff !important; 
}
.banner_countsIn h5 , .banner_countsIn p{
  margin:0px;
}
.get_started{
  text-align: center;
  margin-top: 60px;
}
.phn_setup{
  margin-bottom: 15px;
    display: block;
    text-align: center;
}

.banner_countsIn h5 {
  font-size: 18px;
  font-family: circular-bold,sans-serif;
  text-transform: uppercase;
  color: #031545;
}
.banner_countsIn h2 {
      color: #FF2C2C;
     font-family: circular-bold,sans-serif;
     font-weight: bold;
     font-size: 36px;

}
.banner_countsIn p{
  font-size:13px;
  padding-top: 5px;
  color: #031545;
  text-transform: uppercase;
}
.banner .row{
      justify-content: flex-end;
}
.banner_counts  ul{
  padding:0px;
      display: flex;
      position: relative;
        justify-content: space-between;
}
/* .banner_counts ul li:after {
  content:'';
  border:1px solid #fff;
} */
.resetLink{
  padding-right:0px !important; 
}
.reset_note{
  margin-top:10px;
}
 
p.scuccess_msg span {
    padding: 0px;
  display:block;
      color: #256e40;

}
p.scuccess_msg {
    background: #d4f7e1;
    font-size: 12px;
    padding: 10px;
}
li{
  list-style: none !important;
}
.postdet-content .post_message{
  margin-top: 50px;
}
.postdetails-container{
  margin-bottom: 50px;
  border-bottom: 1px solid #ddd;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(221, 221, 221);
}
footer span{
  display:none;
}
.upgrade_to_view{
  float: left;
  width: 100%;
  text-align: center;
}
.upgrade_to_view .upgradeToView{
  display: inline-block;
  background-color: #fba919;
  padding: 5px;
  border: #fba919 solid;
  border-radius: 5px;
  bottom: 0px;
}
.smo-pgr-recentPost .postdetails-container:nth-last-child(2){
  filter: blur(8px);
}
.react-datetime-picker{
  float: left;
  width: 100%;
  margin: 15px 0px;
  background: #fff;
}
.form-control{
      margin: 0 !important;
}
.al_set {
    display: flex;
    align-items: center;
    justify-content: center;
}
.al_set button{
  width: 100%;
  text-align: center;
  justify-content: center;
  box-shadow: initial !important;
  border: 1px solid #ddd !important;
  border-radius: 5px !important;
}
.resetbutton{
  max-width:220px;
  margin:0 auto;
}
.banner_counts  ul li{
      background: #fff;
    width: 200px;
    border-radius: 100%;
    position: relative;
    height: 200px;
    text-align: center;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
}
.banner_counts ul li:after {
  content: '';
  border: 1px solid #fff;
  width: 230px;
  position: absolute;
  height: 230px;
  border-radius: 100%;
  /* padding: 0px; */
}
.course-image{
  background-image: url('./images/course-image.png');
  width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: right;
}
li{
  list-style: none;
}
section.services {
  /* background: #041c3e; */
  color: #fff;
  position: relative;
  float: left;
    width: 100%;
  /* padding: 15px 0px 30px; */
}

.header_back{
  background: #fff;
  font-size: 16px !important;
}

.site_header {
  font-size: 16px !important;
}

.site_header .login_out a {
  font-size: 16px !important;
}
.header_back .login_out a {
  font-size: 16px !important;
}

/* .t_bar {
  font-size: 14px;
} */

section.slider_set {
  float: left;
  width: 100%;
  background-color: #ececec;
  text-align: center;
  min-height: 900px;
}
.svg_out{
  width: 60%;
  margin: 150px auto 0px;
}

.slider_out{
  position: absolute;
  left: 0;
  right: 0;
  /* background: #fff; */
  /* width: 50%; */
  max-width: 950px;
  margin: 0 auto;
  bottom: -94px;
  /* z-index: 99999; */
  height: 200px;
  border-radius: 10px;
}
.rating{
  text-align:center;
width:100%;
}
.rating img{
  display:inline-block !important;
}
.slick-slider.testimonial_slider.slick-initialized{
  position: absolute;
  left: 0;
  right: 0;
  top: -13%;
}
.slider_set h4{
  font-family:circular-bold,sans-serif;
  text-transform: uppercase;
      color: #4058ec;
}

.testimonial_slider_container {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 200px;
  position: relative;
  /* border-radius: 10px; */
}

.testimonial_slider_container .testimonial_slider {
  position: absolute;
  background: inherit;
  left: 50% !important;
  top: 50% !important;
  transform: translateX(-50%) translateY(-50%) !important;
  max-width: 800px;
  width: 100%;
  min-height: 300px;
  border-radius: 10px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}


.testimonial_set {
  padding: 60px 100px;
  height: 300px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.err-msg-common-tab {
      background: rgba(255,0,0,.3);
    border-radius: 5px;
    margin: 12px 0;
    color: #000;
    padding: 8px 16px;
    display: none;
}
.testimonial_set_content p:last-child {
  margin: 0;
}

.d-ticketing .err-msg-common {
  display: none;
}
.testimonial_set_content img {
  margin: 0 auto;
  margin-bottom: 8px;
  width: 95px;
  height: auto;
  object-fit: contain;
}

.testimonial_slider .slick-prev, .testimonial_slider  .slick-next{
  width: 30px;
  height: 30px;
  z-index: 9;
}
.slider_in{
  min-height: 700px;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  position: relative;
  background-color: #293e69;
}
.cl_set {
  position: absolute;
  left: 0;
  right: 0;
  /* width: 100%; */
  top: 0;
}
.cl_set img{
  width: 100%;
}
.whole_svg {
  background: #01b170;
  padding: 50px;
  
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
}
.whole_svg h3{
  color: #fff;
  font-size: 38px;
  font-family: circular-bold,sans-serif;
}
.whole_svg p{
  font-family: circular-regular,sans-serif;
  color: #fff;
  font-size: 18px;
}
.whole_svg.sv2 {
  background: #0d3269;
}
.svg_image g{
  transition: all 0.5s ease-in-out;
}

/* .svg_image:hover .first{
  transform: matrix(1, 0, 0, 1, 100, 100);
  opacity: 1;
  display: none;
  transition: all 0.5s ease-in-out;
}
.svg_image:hover .second{
  transform: matrix(1, 0, 0, 1, 200, 100);
  display: block;
  transition: all 0.5s ease-in-out;
} */


.pr_title{
  margin-bottom: 50px;
}

section.home_products {
  float: left;
  width: 100%;
  background: #f8f8f8;
  padding-bottom: 100px;
}

.set_checkbox {
  float: left;
  width: 100%;
  margin-bottom: 20px;
 
}
.set_checkbox input , .set_checkbox .checkspan{
  float: left;
  margin-top: 0px;
  margin-right: 10px;
}
.text-center.links{
  margin-top: 50px;
}
.home_products h2 {
  text-align: center;
  padding: 100px 0px;
  font-family: circular-bold,sans-serif;;
  color: #000;
  font-size: 48px;
  text-transform: uppercase;
}

.cl_title h1{
  text-transform: uppercase;
  padding: 50px 0px 30px;
  font-family: circular-bold,sans-serif;
    margin: 0;
    position: relative;
    z-index: 9;
 }

 .cl_title p{
  /* padding-bottom: 15px; */
  position: relative;
  z-index: 9;
  width: 69%;
    margin: 0 auto;
  padding-bottom:30px;
 }

.cl_title{
  color: #fff;
}

.testimonial_slider .slick-prev:before, .testimonial_slider .slick-next:before{
  color: #939393;
  font-size: 32px;
}

.testimonial_slider .slick-prev {
  left: 16px;
}

.testimonial_slider .slick-next {
  right: 16px;
}

.testimonial_slider .slick-list{
  border-radius: 15px; 
}
button.upgrade-planbtn.cutom_price{
  padding: 5px 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: 18px;
}

.cus_price{
 
  text-align: right;
}
div#tsparticles{
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 100%;
  left: 0;
}
.services h2{
  text-align: center;
  display: block;
  width: 100%;
  margin: 0px 0px;
  font-family:circular-bold,sans-serif;
  text-transform: uppercase;
  font-size: 36px;
  margin-top: 110px;
  margin-bottom: 15px;
}
.service_list{
  text-align: center;
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
}
.service_list p {
  transition: all 0.5s ease;
}
.service_list:hover p {
  transform: scale(1.2);
}
.services ul{
  list-style: none;
  display: flex;
  justify-content: space-between;
  /* flex-flow: row; */
  width: 100%;
  margin: 30px 0px;
  padding: 0;
}
.services p{
  margin-top: 15px;
  color: #fff;
  min-height: 40px;
}
.services li{
  max-width: 8%;
  margin: auto;
}

a#billing_address {
  color: #293e69;
  font-family:circular-bold,sans-serif;
  margin-bottom: 15px;
  display: block;
}
.gate_way .row{
  margin:0px;
  
}
.total_count{
  border: 1px solid #ddd;
  max-width: 70%;
  padding: 10px;
  margin-top: 50px;
  margin-left: 50px;
  border-radius: 5px;
}
.total_count h6{
  color: #293e69;
  font-family:circular-bold,sans-serif;
  margin-bottom: 18px;
}
.total_setting span:nth-child(1){
  color: #293e69;
  font-family:circular-bold,sans-serif;
}
.total_setting span:nth-child(2){
  float: right;
}
p.total_setting {
  margin: 0;
  border-top: 1px solid #ddd;
  padding-top: 5px;
}
.total_count p{
  color: #495057;
}
.set_size{
  background: #FFF;
  max-width: 85%;
  margin: 0 auto !important;
}
.netbaning_type a {
  color: #666;
  float: left;
  width: 100%;
  border: 1px solid #666;
  border-radius: 5px;
  margin: 10px 0px;
  padding: 5px 10px;
  font-size: 14px;

}
.upi.payment_mode , .netbanking.payment_mode , .creditcard.payment_mode{
  float: left;
  width: 100%;
}
.netbaning_type a span:nth-child(1) {
  float: left;
  width: 30px;
  padding: 5px;
}
.netbaning_type a span:nth-child(2) {
  float: left;
  padding: 7px 0px;
  width: 99px;
}
.set_arrow > a:after {
  content: '\f078';
  font-family: 'fontawesome',sans-serif;
  position: absolute;
  /* right: 0; */
  top: 26px;
  right: -5px;
}
.form-cusinl-b2 button:active, .form-cusinl-b2 button:hover, .form-cusinl-b2 button:focus {
  background-color: #248f48 !important;
}
.career_soci ul {
  margin-bottom: 0px !important;
}
/* .change_service.service_desc {
  padding-bottom: 0px;
} */
.nav-item.set_arrow {
  margin-right: 10px;
}
/* .set_arrow > a:hover:after {
  transform: rotate(180deg);
} */
.ddownarrow:after {
  content: '\f078';
}
.duparrow:after {
  content:"\f077";
}
.custom_radio {
  display: block;
  position: relative;
  padding-left: 30px;
  float: left;
    width: 100%;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.payment label {
  float: left;
  width: 100%;
  cursor: pointer;
}
.payment_mode {
  display: none;
}
.padding_none{
  padding: 0px !important;
}
.s_experiy .form-control{
  float: left;
  width: 43%;
  margin-right: 10px;
}
.s_experiy label{
  width: 100%;
}
.package_type{
  float: left;
  width: 100%;
  text-align: center;
  background: #fba919;
  padding: 8px;
  text-transform: capitalize;
  /* margin-bottom: 20px; */
}
.react-responsive-modal-modal .form-control{
  height: 40px !important;
}
.react-responsive-modal-modal h3{
  margin-bottom: 15px !important;
}
.react-responsive-modal-container .btn-primary{
  width: 40%;
  float: right;
}
/* .react-responsive-modal-modal{
  width: 400px !important;
  
} */
.cvv_set .place_set {
  right: 6%;
  top: 54%;
}
.form-group.col-lg-6.col-md-6.col-sm-6.col-xs-6.padding_none.s_experiy , .form-group.card_number.cvv_set.col-lg-6.col-md-6.col-sm-6.col-xs-6 {
  float: left;
  padding: 0;
}
/* .payment .form-control {
  height: 45px;
  float: left;
  border-radius: 5px;
  font-family: opansansebold,sans-serif;
  color: #666;
} */
.place_set {
  position: absolute;
  top: 41%;
  left: auto;
  color: #666;
  right: 3%;
  font-size: 18px;
}
.radio_checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff !important;
  border-radius: 50%;
  border: 1px solid #6666;
}
.container-fluid.gate_way{
  color: #293e69;
  margin-top: 50px;
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
}
.container .custom_radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.container input:checked ~ .radio_checkmark {
  background-color: #2196F3;
}
.select_payment form {
  padding: 20px 20px !important;
  float: left;
  width: 100%;
}
.container .radio_checkmark:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: #009ef9;
}
.payment_form {
     /* border: 1px solid #009EF9; */
     max-width: 53%;
     /* padding: 0px; */
     background: #fff;
     /* padding: 35px; */
     border-bottom: 0px;
     padding: 35px 35px 0px 35px;
     -webkit-box-shadow: 0px -1px 5px 0px rgba(221,221,221,1);
     -moz-box-shadow: 0px -1px 5px 0px rgba(221,221,221,1);
     box-shadow: 0px -1px 5px 0px rgba(221,221,221,1);

}
.payment form {
  box-shadow: initial;
  background: #fff;
  float: left;
  width: 100%;
  padding: 10px;
}
.h_we_work .contact_banner{
  height: 520px;
}
select#sel1{
  padding: 0px;
  background: initial;
  border: 0px;
  font-family: circular-regular,sans-serif;
  color: #fff;
}
.t_bar {
  float: left;
  width: 100%;
  font-size: 14px;
  /*padding: 12px 0px 15px 0px;*/
  background: #0c1a41;
}
.t_bar ul li:nth-child(3){
  background: #01b170;
  padding: 2px 15px;
  border-radius: 5px;
}
.t_bar ul li:nth-child(2){
  padding: 11px 2px;
}
.t_bar ul li:nth-child(3) a{
  font-size: 16px;
    padding: 0px;
    border-radius: 5px;
    font-family: circular-regular,sans-serif;
}
.f_mobile{
  font-size: 20px;
}
.t_bar a {
  color: #fff;
  font-size:16px;
}
.t_bar ul{
  align-items: center;
  margin: 0;
  /* padding: 0; */
  width: 40%;
  float: right;
  display: flex;
  justify-content: space-around;
  padding: 0px;
}
 .mail_form{
  max-width: 400px;
  margin: 20px auto;
 }
 .invite_friends{
  text-align: center;
  /* padding: 0px 30px; */
  max-width: 500px;
  margin: 0 auto;
 }
.mail_form .form-group{
    display: flex;
    float: left;
    width: 100%;
}
.mail_form  .input_label{
  min-width: 150px;
  /* padding: 10px 0px; */
}
.react-tel-input .form-control{
  width: 100% !important;
}
/* .react-responsive-modal-modal{
  border-radius: 15px !important;
  width: 600px !important;
} */
.payment .form-control {
    height: 45px;
    float: left;
    border-radius: 5px;
    font-family: opansansebold,sans-serif;
    color: #666;
}
.card-body button{
      float: left;
    width: auto;
}
.footer ul{
overflow: hidden;
  width: 100%;
  border-top: 1px solid #ddd;
  margin-top: 30px;
  padding: 15px 0px 15px 0px;
}
.footer li{
  float: left;
  margin-right: 30px;
}
/*admin*/
.footer li:nth-child(2){
  margin-left: 100px;
}
.card-body{
  float:left;
  width:100%;
}
.page-link{
      color: #321fdb !important;
}
.page-item.active .page-link{
   color: #fff !important;
}
.rdw-editor-main{
  height: 200px !important;
  border: 1px solid #ddd;
}
.rdw-editor-toolbar{
  margin: 0px !important;
}

/*admin*/
button.btn.btn-primary.btn-sm {
  margin-right: 10px;
}
.footer li a{
  color: #000;
  font-family:circular-bold,sans-serif;
}
.card-footer  button{
      float: left;
    width: auto;
}
.we_work .work_in_col {
  padding-left: 8px;
  padding-right: 8px;
}
.payment_type span {
  margin-right: 20px;
  background: #fff;
  color:#293e69;
  border: 1.5px solid #293e69;
  padding: 1px;
  border-radius: 100%;
  width: 25px;
  display: inline-block;
  font-size: 14px;
  height: 25px;
  text-align: center;
  /* background: #009ef9; */
}
.btn-success.nxt_step {
  width: 110px;
  padding: 3px;
  font-size: 15px;
}
.cntpg_banner_sbt {
  padding: 3px 20px !important;
  font-size: 15px !important;
  border-radius: 5px !important;
  margin-top: 10px;
}
.work_in{
  width:100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  margin-bottom: 16px;
  color: #fff;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: flex-end;
  border-radius: 5px;
}
.work_in a:hover {
  color: #fff !important;
}
.work_in h3{
  padding-right: 30px;
  font-family:circular-bold,sans-serif;
}
section.we_work {
  text-align: center;
  margin: 50px 0px;
  float:left;
  width:100%;
  overflow:hidden;
  margin-top: 20px;
  margin-bottom: 10px;
}
.we_work p{
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
  color: #000;
  margin-bottom: 30px;
  margin-top: 25px;
  font-size: 16px;
}
 .we_work h1 , .online_course h1 , .membership_program h1{
  font-family:circular-bold,sans-serif;
  color: #000;
  text-transform: uppercase;
 }
 .online_course {
   /* height: 90vh; */
   padding-top: 0px;
   padding-bottom: 0px;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 .online_img{
  float: right;
 }
 /* .navbar-light .navbar-brand{
  margin-left: 10%;
 } */
 .online_content h2{
  font-family:circular-bold,sans-serif;
  color: #000;
  margin-bottom: 30px;
 }
 .online_content p{
    line-height: 30px;
    font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
    color: #000;
    font-size: 16px;
    letter-spacing: 0.5px;
 }
.online_content button.btn.btn-success{
  font-size: 18px;
  margin-top: 25px;
  width: 155px !important;
  height: 50px;
}
.online_content button.btn.btn-success a {
  color: #fff;
}
.online_course h1{
  text-align: center; 
}
.slick-track , .slick-slider.slick-initialized , section.membership_program{
  outline:0 !important;
}
.cusmt-5 {
  margin-top: -5px;
}
.membership_program h1{
    font-size: 35px;
    margin: 0px;
    line-height: 38px;
    padding-bottom: 15px;
    min-width: 500px;
}
.mem_set a{
  font-size: 22px;
}
.mem_set{
  width: 50%;
  margin: 20px 0px 20px;
  padding-left: 99px;
}
.c_set_ppad {
  padding-top: 10px;
}
.c_set {
  position: absolute;
  /* align-items: center; */
  /* justify-content: center; */
  color: #123161;
  /* text-align: center; */
  background: #00B170;
  left: -80px;
  top: 50%;
  transform: translateY(-50%);
  padding: 15px 0px;
  width: 56vw;
  bottom: 25px;
  padding-right: 60px;
  min-height: 290px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.membership_program .t_title{
  text-align: center;
  margin: 50px 0px;
}
.hv_img{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.c_set p{
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
  text-transform: inherit;
  font-size: 16px;
  color: #fff;
  text-align: left;
}
.membership_program .slick-dots li button:before{
  font-size: 18px;
  top: 20px;
  
}
.membership_program .slick-dots li.slick-active button:before{
  color: #293e69;
}
/* section.membership_program{
  overflow: hidden;
  padding-bottom: 0px;
} */
.membership_program .slick-dots{
  bottom: -40px;
}
.hv_content_out{
  display: flex;
    align-items: center;
    justify-content: center;
}
.hv_content{
  width: 90%;
  padding-left: 30px;
}
.c_set h3{
  font-family:circular-bold,sans-serif;
  font-size: 25px;
  color: #fff;
  margin-top: 15px;
  text-align: left;
}
.css-1okebmr-indicatorSeparator {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    background-color: hsl(0deg 0% 100%) !important;
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
    box-sizing: border-box;
}
section.let_talk{
  min-height: 250px;
  display: flex;
  cursor: pointer;
  align-items: center;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-color: #01121c;
  background-position: center center;
  transition: all 0.5s ease-in;
  width: 100%;
}
 section.let_talk:hover:before {
  content: '';
  background: #00c4ff91;
  position: absolute;
  width: 100%;
  opacity: 1;
  height: 100%;
  transition: all 0.5s ease-in;
}
section.let_talk:before{
  opacity: 0;
}
/* section.let_talk {
  background-position: center center;
} */
section.let_talk a{
  color: #fff;
  border: 4px solid #fff;
  font-size: 18px;
  /* margin: 0; */
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
  border-radius: 100%;
  width: 175px;
  height: 175px;
  overflow: hidden;
  z-index: 1;
  position: relative;
  transition: all 0.5s ease-in;
  /* float: left; */
  display: flex;
  /* padding: 5px 0px; */
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
section.let_talk:hover a{
  border-radius: 0px;
}
.nav-item button.btn.btn-success{
  width: 130px;
  font-size: 16px;
  height: auto;
  padding: 10px 0px;
  background: #fff;
  color: #000;
  border-color: #fff;
}
.navbar{
  padding: 15px 0px;
}
/* .v_set{
  height: 250px;
  background-size: cover;
} */
.back_video{
  position: absolute;
  width: 100%;
  z-index: -9;
  height: 80%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
}
video.videoTag{
  width: 100%; 
}
a.tag {
  border: 1px solid #6e3e3e;
  padding: 2px 10px;
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
  font-size: 14px;
  color: #000 !important;
  margin-bottom: 30px;
  display: inline-block;
}
.latest_blog p{
  font-size: 18px;
  font-family:circular-bold,sans-serif;
  color: #fff;
  /*text-align: center;*/
}
.bl_detail{
  margin: 30px 0px;
  text-align: center;
  float: left;
  width: 100%;
}
.more_blogs{
  color: #000;
  text-decoration: none;
  display: block;
  text-align: center;
  font-family:circular-bold,sans-serif;
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
}
.slider_content{
  overflow:hidden;
}
.blog_content{
  width: 80%;
  margin: 0 auto;
  padding-bottom: 10px;
}
.blog_in{
  border: 1px solid #ddd;
  -webkit-box-shadow: 0px 1px 5px 1px #ddd;
  -moz-box-shadow: 0px 1px 5px 1px #ddd;
  box-shadow: 0px 1px 8px 1px #ddd;
}
.latest_blog h1{
  margin: 70px 0px;
  text-align: center;
  font-family: circular-bold,sans-serif;
}
.br_more {
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.br_more a{
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
  color: #00b170;
  font-size: 18px;
}
footer ul{
  padding: 0px;
}
.f_details li {
  line-height: 36px;
}
.f_details h4{
  font-size: 18px;
  margin-bottom: 30px;
  font-family: circular-bold,sans-serif;
  text-transform: uppercase;
}
.footer-info-contact a{
  display: block;
}
.footer-info-contact p{
  margin: 0px;
}
.footer-info-contact {
  margin-bottom: 15px;
  padding-left: 15px;
  font-family: circular-regular,sans-serif;
}
.f_details li a , .footer-info-contact a{
  color: #fff;
}
.seo_details {
  height: 500px;
  margin-top: 30px;
  background: #fff;
  font-family: circular-bold,sans-serif;
  overflow-y: scroll;
}
.check_icon{
  position: absolute;
  left: 20px;
}
.g_icons{
  display: flex;
    justify-content: space-between;
    width: 75px;
}
.about_detail h4{
  font-family: circular-bold,sans-serif;
  margin-top: 30px;
  width: 100%;
  border-bottom: 1.2px solid #ddd;
  padding-left: 30px;
  padding-bottom: 10px;
  color: #293e69;
}
.seo_details li{
  font-family: circular-bold,sans-serif;
  color: #293e69;
}
 
h2.b_title {
  text-align: center;
  margin: 50px 0px 30px;
  background: initial;
  float: left;
  width: 100%;
  font-family: circular-bold,sans-serif;
  font-size: 24px;
}
.upgrade button{
  border: 0;
  display: inline-block;
  background: #01b170;
 
  width: 230px;
  color: #fff;
  height: 50px;
  border-radius: 100px;
  font-family: circular-bold,sans-serif;
}
.upgrade{
  float: left;
  width: 100%;
  text-align: center;
}
.seo_details .set_black{
  color: #000;
}
.seo_details .row{
  padding: 30px 0px;
  display: flex;
    align-items: center;
    border-bottom: 1.2px solid #ddddddba;
    margin: 0;
}
.seo_details ul{
  margin:0px;
}
/* footer{
  background-color: #05102e;
  padding: 50px 0px 30px;
  color: #fff;
  padding-top: 50px;
  background-size: cover;
    background-repeat: no-repeat;
} */
.bgv_set{
  background:#fcfcfc;
  height: 100vh;
}
.social_set {
  display: flex;
}
.social_set li{
  margin-right: 15px;
}
.footer-info-contact .ex_icon{
  position: absolute;
  left: 0;
}
.copy_rights{
  text-align: center;
  border-top: 1px solid;
  padding-top: 10px;
}
.leads_call{
  color: #fff;
    background: #293e69;
    padding: 15px 30px 30px 30px;
    margin-top: 100px;
    margin-bottom: 50px;
    width: 80%;
    float: right;
}
ul.leads_call h2 {
  font-size: 18px;
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
  text-transform: uppercase;
  background: #cdcdcd;
    color: #000;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
}
ul.leads_call li {
  border: 1px solid transparent;
  margin-top: 20px;
  border-radius: 7px;
  background: #fff;
}
.br_set {
  padding: 0px;
  float: left;
  margin-top: 20px;
}
.br_set li {
  float: left;
  width: 25%;
  text-align: center;
}
.br_set li  img{
  max-height: 50px;
}
.navbar.navbar-light .navbar-nav .nav-link:hover{
  color:#01b170 !important;
  transition: all 0.5s ease-in-out;
}
ul.sub_service:before , ul.sub_membership:before {
  content: "";
  position: absolute;
  width: 0;
  top: 5px;
  height: 0;
  border-color: transparent transparent #fff #fff;
  border-style: solid;
  border-width: 6px;
  transform-origin: 0 0;
  transform: rotate(135deg) translateX(-50%);
  left: 15%;
  box-shadow: -2px 2px 2px 0 hsla(0,0%,43.9%,.2);
}
.br_set li p{
  font-size: 14px;
  line-height: initial;
  margin-top: 10px;
  width: 69%;
  /* margin: 10px auto 0px; */
}
.v_set {
  height: 250px;
  background-size: cover;
  position: absolute;
  border: 3px solid #fff;
}

.v_set.video3 {
  left: 0;
  width: 400px;
}
.v_set.video1 {
  right: 0;
  width: 400px;
  bottom: 0;
}
.v_set.video2 {
  right: 0;
  top: -32%;
  width: 350px;
}
.online_course .row{
  position: relative;
  margin-top: 120px;
}
.h_content{
  position: relative;

}
.blog_content ul{
    display: flex;
    align-items: center;
    width: 35%;
    justify-content: space-evenly;
    padding: 0;
    margin: 10px auto 20px;
}
.blog_content li{
  font-size: 18px;
}
.blog_content li:nth-child(1) a{
  color: #4b669b;
}
.blog_content li:nth-child(2) a{
  color: #0376b5;
}
.blog_content li:nth-child(3) a{
  color: #59a9e6;
}
section.online_course {
  float: left;
  width: 100%;
  margin-top: 0px !important;
  padding-top: 30px;
  padding-bottom: 30px;
}
section.membership_program {
  overflow: hidden;
  padding-bottom: 0px;
   float: left;
    width: 100%;
  margin-top: 0px;
}
 section.latest_blog ,footer{
    float: left;
    width: 100%;
    margin-top: 20px;
}

/* section.let_talk {
  width: 100%;
} */
.hovered_content{
  opacity: 0;
  position: absolute;
  display: flex;
  top: 0;
  /* display: flex; */
  width: 100%;
  color: #fff;
  background: #3045a07d;
  left: 0;
  height: 100%;
  right: 0;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  transition: 0.5s all ease-in-out;
}
.hovered_content p{
  color: #fff;
  margin: 15px;
}
.work_in:hover .hovered_content{
    opacity: 1;
    cursor: pointer;
}
.work_in > h3 {
  transition: 0.5s;
}
.work_in:hover > h3 {
  opacity: 0;
}
.hovered_content>div{
  width: 45%;
}
.hovered_content a{
  color: #ecd241;
  font-family: circular-bold,sans-serif;
}
.content_box.cntpgbancontd1 p, .content_box.cntpgbancontd1 h5 {
  display: block;
}
.service_banner {
    height: 520px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
}
.b_content {
  width: 80%;
  float: right;
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
 
}
.b_content h3{
  margin-bottom: 10px;
      font-family: circular-bold,sans-serif;
    text-transform: uppercase;
    font-size: 70px;
      margin-top: 10px;
}
#b2bimg{
  padding:50px 0px;
  background:whitesmoke;
  padding-bottom: 0px;
}
.imgb2b{
      width: 100%;
    
      margin: 50px 0px;
    justify-content: space-between;
    text-align: center;
}
.b2bcontents h4{
      margin-bottom: 50px;
    color: #000;
    font-family: circular-bold,sans-serif;
    letter-spacing: 0.5px;
      font-size: 22px;
}
.imgb2b p{
      font-size: 18px;
    margin-top: 30px;
    width: 80%;
      font-family: circular-bold,sans-serif;
    /* margin: 30px auto 0px; */
}
.videosPlayer{
  /*border:1px solid #eee;*/
  margin:100px 0px;
}
.join_out{
      float: left;
    width: 100%;
    text-align: center;
}
ul.f_links.show , .f_services.show{
  display:block !important;
}
.f_details.f_links_out.show i:before  , .f_details.f_services_out.show i:before{
  content:'\f068';
}
ul.f_links.show , .f_extra.show{
  display:block !important;
}
.f_details.f_links_out.show i:before  , .f_details.f_extra_out.show i:before{
  content:'\f068';
}
.f_details h4 span{
      float: left;
    cursor: pointer;
    border: 1px solid;
    border-radius: 100%;
    width: 19px;
    font-size: 11px;
    height: 19px;
    text-align: center;
    line-height: 19px;
    margin-right: 10px;
}
.join_now {
    font-size: 1rem;
    background: #01b170;
    padding: 10px 20px;
    text-align: center;
    color: #fff !important;
  /*margin:50px 0px;*/
      display: inline-block;
    font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
    font-family: circular-bold,sans-serif;
}
.b2bcontents p{
      letter-spacing: 0.3px;
    font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
    line-height: 36px;
      font-size: 20px;
        margin-bottom: 30px;
}
.memvdocont {
  position: relative !important;
  margin: auto !important;
}
.memcontent{
  position: sticky;
  top: 100px;
      max-width: 100%;
    margin: 70px auto;
    font-family: circular-bold,sans-serif;
}
.b2bcontents {
      width: 67%;
    text-align: center;
    margin: 0 auto;
}
.memcontent p{
        font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 36px;
}
.faqcontent{
      max-width: 100%;
    margin: 100px auto;
}

.faqcontent p{
        font-size: 16px;
    /*letter-spacing: 0.3px;*/
    line-height: 23px;
}
 .memcontent p b{
       font-size: 18px;
    letter-spacing: 1px;
    font-family: circular-bold,sans-serif;
 }
 .divider{
  display: inline-block;
  width: 0;
  height: 1em;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
 }
.b_content h1{
  width: 70%;
      font-family: circular-bold,sans-serif;
  float: left;
  font-size: 70px;
}

.hanges_text_in{
  background-image: url('./images/service/app_promotion_2.png');
  width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: -30px;
    background-position: center;
    top: -30px;
}
.hanges_text{
  height: 350px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  
  float: right;
  position: relative;
  right: 0;
  bottom: 0;
  background: #293e69;
    padding: 0px 80px;
    z-index: 1;
    top: -180px;

}
.col-lg-6.pos_in{
  box-shadow: 1px -1px 10px 0px #ddd;
  border-radius: 15px;
  background: #fff;
  padding: 30px;
  max-width: 48%;
}
.pos_in ul li:nth-child(1):after{
  content: '';
  border: 0.5px solid #dddddd29;
  position: absolute;
  top: 0;
  right: -30px;
  height: 100%;
  background: #dddddd6b;
}
.pos_in ul {
  display: flex;
  padding: 0;
  margin: 0px;
}
.pos_in ul p{
  margin: 0px;
}
.pos_in ul h5{
  font-family: circular-bold,sans-serif;
  font-size: 16px;
}
.pos_in ul li:nth-child(1){
  width: 60%;
  position: relative;
}
.pos_in ul li:nth-child(2){
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
  margin-right: 30px;
}
.col-lg-10.about_list{
  box-shadow: 1px -1px 10px 0px #ddd;
  border-radius: 15px;
  background: #fff;
  padding: 30px;
  max-width: 70%;
}
.about_list ul li:nth-child(1):after{
  content: '';
  border: 0.5px solid #dddddd29;
  position: absolute;
  top: 0;
  right: 0px;
  height: 100%;
  background: #dddddd6b;
}
.about_list ul li:nth-child(2):after{
  content: '';
  border: 0.5px solid #dddddd29;
  position: absolute;
  top: 0;
  right: 0px;
  height: 100%;
  background: #dddddd6b;
}
.about_list ul {
  display: flex;
  padding: 0;
  margin: 0px;
}
.about_list ul p{
  margin: 0px;
}
.about_list ul h5{
  font-family: circular-bold,sans-serif;
  font-size: 16px;
}
.about_list ul li:nth-child(1){
  width: 33%;
  text-align: center;
  position: relative;
}
.about_list ul li:nth-child(2){
  width: 33%;
  text-align: center;
  position: relative;
}
.about_list ul li:nth-child(3){
  width: 33%;
  text-align: center;
  position: relative;
}
/*.about_list ul li:nth-child(2){
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 33%;
  margin-right: 30px;
}*/
.tableprimary{
  color: #4f5d73;
    background-color: #B2EBF2 ! important;
}
.abt_td{
    background-color: #33a5ff0f ! important;
}
.abt_head{
  margin-bottom: 25px;
    font-family: 'circular-bold',sans-serif;
    /*color: #524b8b;*/
}
.about_table{
  box-shadow: 1px -1px 10px 0px #ddd;
}
.like{
    font-size: 16px;
    padding: 4px;
  }
  .likeper{
    font-size: 29px;
    
    font-family: 'circular-bold',sans-serif;
  }
   .ctgy{
    padding: 5px;
  }
.other_services .col-lg-8{
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
}
.b_set:hover{
border-color: #3AA9FF;
}
.b_set{
    border-radius: 100%;
    width: 70px;
    margin: 0 auto;
    height: 70px;
    cursor: pointer;
    text-align: center;
    padding-top: 20px;
    background: #fff;
    -webkit-box-shadow: 1px 0px 5px 0px rgba(221,221,221,1);
    -moz-box-shadow: 1px 0px 5px 0px rgba(221,221,221,1);
    box-shadow: 1px 0px 5px 0px rgba(221,221,221,1);
}
.other_services .col-lg-2{
  text-align: center;
  margin-bottom: 30px;
}

.other_services .col-lg-2 p{
  margin-top: 10px;
  /*color: #000;*/
  font-family: circular-bold,sans-serif;
}
.posible{
margin-top: 0px;
}
.seo , .about, .questions, .others_service , .upgrade_side , .custome , .mode , .logout , .ourwork{
  background-image: url(./images/seo.png);
  background-size: contain;
  width: 20px;
  height: 20px;
  
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 15px;
  margin-top: 3px;
}

.ourwork{
  background-image: url(./images/our_work.png); 
}
.questions{
  
    background-image: url(./images/question_white.png); 
}
.logout{
  background-image: url(./images/log_blue.png);
}

.about{
  background-image: url(./images/about_icon.png);
}
.others_service {
  background-image: url(./images/other_service.png);
}
.upgrade_side {
  background-image: url(./images/upgrade.png);
}
.custome {
  background-image: url(./images/custom.png);
}
.mode{
  background-image: url(./images/dark.png);
}

 #sidebar ul.components li {
    margin-bottom: 10px;
}

.RRT__panel{
      border: 0px !important;
}
.RRT__tabs{
      border-bottom: 1px solid #ddd;
        margin-top: 50px;
          min-width: 200px;
        text-align: center;
}
.dmOut{
      height: 500px;
    margin-bottom: 50px;
    overflow-y: scroll;
      border: 1px solid #000;
}
.ecommerce .slick-prev:before, .ecommerce .slick-next:before{
      color: #cbcbcb;
        font-size: 32px;
      
}
.ecommerce .slick-prev{
      left: -70px;
        top: 35%;
}
.ecommerce .slick-next{
         right: -65px;
           top: 35%;
}
.RRT__container .RRT__tab--selected{
      background: #fff !important;
    box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 24%);
}
.out_sl{
  outline:0px;
}

.RRT__tab {
    background: #f3f3f3 !IMPORTANT;
      font-family: circular-bold,sans-serif;
        min-width: 150px;
          border-color: #f3f3f3 !important;
    border-style: initial !important;
}
.url_link  , .url_desc{
  display:flex;
      margin-bottom: 10px;
}
.url_link h6 , .url_desc h6{
      min-width: 100px;
        text-align: right;
}
.url_link a  , .url_desc p{
  padding-left:20px;
      font-size: 14px;
        color: #6b7996;
}
.url_desc p{
      line-height: 26px;
}
.comments_set input{
      width: 60%;
    height: 50px;
}
.all_ques {
    margin: 30px auto 0px;
}
.all_ques p{
          background: #f0f0f0;
       padding: 15px 15px 15px 40px;
    font-size: 14px;
    color: #000000d9;
      line-height: 26px;
  position:relative;
}
.all_ques p:before{
  content: '';
    position: absolute;
    border: 2px solid #2c3e64;
    height: 52%;
    left: 22px;
    top: 25%;
}
.comments_out{
      padding-left: 45px;
}
.all_ques_in {
    padding-bottom: 50px;
    margin-bottom: 25px;
    border-bottom: 1px solid #ddd;
}
.comments_set button.btn.btn-success{
      width: 130px;
        background-color: #01b170;
        left: 10px;
    font-size: 16px;
    border-radius: 5px;
    height: 45px;
    position: relative;
    top: -7px;
}
#sidebar ul li a.active .ourwork , .work_out:hover .ourwork{
  background-image: url(./images/work_hover.png); 
}
#sidebar ul li a.active .weeklyicon , .weekly_out:hover .weeklyicon{
  background-image: url(./images/calendarsidebar.png); 
}

#sidebar ul li a.active .questions , .ques_out:hover .questions{
  background-image: url(./images/question_hover.png); 
}

#sidebar ul li a.active .seo , .seo_out:hover .seo{
  background-image: url(./images/seo_blue.png);
}
#sidebar ul li a.active .about , .about_out:hover .about{
  background-image: url(./images/about.png);
}
#sidebar ul li a.active .others_service , .others_out:hover .others_service{
  background-image: url(./images/other_blue.png);
}
#sidebar ul li a.active .upgrade_side , .upgrade_out:hover .upgrade_side{
  background-image: url(./images/up_blue.png);
}
#sidebar ul li a.active .custome , .custom_out:hover .custome{
  background-image: url(./images/custom_blue.png);
}
#sidebar ul li a.active .mode , .mode_out:hover .mode{
  background-image: url(./images/mode_blue.png);
}
 .log_out:hover .logout{
  background-image: url(./images/log_blue.png);
}

.other_services{
  /*background: #fcfcfc;*/
  padding-top: 40px;
  height: 100vh;
}
section.ex_text{
  float: right;
  width: 70%;
}
a.aded{
  color:#fff !important;
    /*text-transform: capitalize;*/
}
li.nav-item {
  margin-left: 15px;
  position: relative;
}
.hanges_text p{
  font-size: 24px;
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
}
.service_desc .seo_content p{
  margin-bottom:0px;
  color: #000;
  font-size: 16px;
}

ul.sub_membership.course_list , ul.sub_membership.tools_list , ul.sub_membership.members_list{
  width: auto;
}
ul.sub_membership.course_list:before , ul.sub_membership.tools_list:before , ul.sub_membership.members_list:before{
  left: 50%;
}
.logo .seo_content p{
  text-align: center;
}
.service_desc h1{
    font-family: circular-bold,sans-serif;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #000;
      letter-spacing: 2px;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 50px;
}
section.service_desc{
  float: left;
  width: 100%;  
  padding-bottom: 0px;
  position: relative;
    top: -125px;
}
.video-react{
  width:500px !important;
}
.service_desc p{
  line-height: 36px;
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
}
.service_client ul{
  display: flex;
  justify-content: space-around;
}
section.service_client {
  float: left;
  width: 100%;
  margin: 100px 0px 30px;
}
section.change_service.service_desc {
  text-align: center;
  padding-top: 70px;
  top:0;
}
.membership_program .slick-list {
  margin-bottom: -6px;
}
section.change_service.service_desc h2{
  width:100%;
  text-transform: uppercase;
    font-size: 36px;
  color:#000;
      margin-bottom: 30px;
    font-family: circular-bold,sans-serif;
    letter-spacing: 2px;
}
svg.arrow-end{
      position: absolute;
    top: 198px;
    left: auto;
    transform: rotate(155deg);
    right: 55px;
}
/* .circle-rotate{
  position: relative;
} */
section.our_servces{
  float: left;
  width: 100%;
  background: #293e69;
  color: #fff;
  min-height: 540px;
  margin-top: 15px;
}
section.our_servces .slick-list {
  padding: 35px 0px;
}
section.our_servces .slick-dots {
  bottom: 15px;
}
section.our_servces .slick-dots li button:before {
  background: #fff;
}
.circle-svg{
  position: relative;
}
.ser_setout{
  position: relative;
  background: #293e69;
  z-index: 9;
  height: 100%;
}
.set_dots .slick-dots li button:before{
  left: -40px;
  width: 20px;
  height: 20px;
  top: 34px;
  transition: all 0.5s;
  color: #fff;
  font-size: 18px;
  opacity: 1;
  background: #fff;
  border-color: #fff;
}
.set_dots .slick-dots li button.active:before{
  color: transparent;
  font-size: 18px;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 5px #00B170;
  
}
.set_dots ul li:nth-child(4) button:before {
  left: -41px;
  top: 25px;
}

.set_dots ul li:nth-child(5) button:before {
    left: -45px;
    top: 27px;
}

.seo_img h6{
    text-align: center;
    max-width: 85%;
    line-height: 30px;
    font-size: 20px;
    color: #000;
    margin: 0 auto 50px;
    font-family: circular-regular,sans-serif;
}
.tr_seo div:nth-child(1) , .tr_seo div:nth-child(2){
  background-image:url('./images/graph.png');
  background-repeat: no-repeat;
    background-size: contain;
    width: 150px;
    height: 180px;
    float: left;
    background-position: center;
      text-align: center;
    box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 27%);
    padding: 30px 0px;
    border: 1px solid #dddddd4d;
    transition: 0.7s;
}
.footer-info-contact span {
  display: block;
}
.tr_seo h5{
  font-family: circular-bold,sans-serif;
}
.tr_seo {
    float: initial;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 50px auto;
}
.tr_seo div:nth-child(1) p  , .tr_seo div:nth-child(2) p{
  font-size:12px;
}
.seo_down{
    float: left;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #ddd;
    position: absolute;
    /* top: auto; */
    max-width: 81%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 275px;
}
.seo_activity{
      display: flex;
    max-width: 50%;
    margin: 0 auto;
    justify-content: space-between;
      align-items: center;
}
.seo_activity p{
      text-transform: uppercase;
    font-size: 14px;
}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}
.slider.round:before {
    border-radius: 50%;
}
.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
.switch input {
    display: none;
}
input.default:checked + .slider {
    background-color: #3c57fe;
}
input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
.slider.round {
    border-radius: 34px;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
.seo_activity div{
  min-width:90px;
      text-align: center;
}
/* .set_dots .slick-dots li button:before{
  color: #fff;
  font-size: 18px;
  opacity: 1;
  background: #fff;
    border-color: #fff;
} */
.circle-rotate {
  position: absolute;
  top: 0;
  -webkit-transition: transform 0.8s, opacity 0.8s;
  transition: transform 0.8s, opacity 0.8s;
  -webkit-transform: rotate(-15deg);
  transform: rotate(-15deg);
      width: 100%;
}
.circle_out{
  position: absolute;
  left: -261px;
  right: 0;
  margin: 0 auto;
  width: 780px;
  z-index: 9;
  top: -12%;
}
.set_dots{
  position: absolute;
  bottom: 100px;
  z-index: 9999;
  float: left;
  width: 90%;
}
.set_dots ul{
  height: 388px;
  width: 500px;
  float: left;
  position: relative;
}
.set_dots ul li:nth-child(1){
  left: 100px;
}
.set_dots ul li:nth-child(2){
  top: 100px;
  left: 140px;
}
.set_dots ul li:nth-child(3){
  top:200px;
  left: 150px;
}
.set_dots ul li:nth-child(4){
  top:300px;
  left: 140px;
}
.set_dots ul li:nth-child(5){
  top:400px;
  left: 100px;
}
.set_dots ul li:nth-child(3) button:before{
  left: -37px;
}
.set_dots .slick-dots li{
  width: auto;
  margin-bottom: 15px;
  height: auto;
  text-align: left;
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
    font-size: 21px;
  position: absolute;
}
.set_dots .slick-dots{
     bottom: 0px;
    left: 108px;
    width: auto;
    right: 0;
}
.as_nav{
  position: relative;
  z-index: 9;
}
.hole_service {
  position: relative;
}
.se_set{
  padding-top: 100px;
  width: 66%;
  margin: 0 auto;
}
.se_set h1{
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
  font-size: 24px;
  margin-bottom: 25px;
}
.se_set p{
  line-height: 30px;
  margin-top: 25px;
  font-size: 17px;
}
 
/* Client Dashboard */
.ags ul {
  padding: 0px;
  display: flex;
    justify-content:space-evenly;
}
 
.asoab_panel {
  float: left;
  width: 100%;
  height: 100vh;
}
.asoab_panel .input-group-append button{
  border-radius: 5px !important;
}
.asoab_panel input{
  margin-right: 20px !important;
  border-radius: 5px !important;
}
/* .container-fluid.footer{
  width: 100%;
  display: block;
  position: relative;
  margin-top: 50px;
} */
.apexcharts-legend-text{
  line-height: 36px;
    font-size: 16px !important;
    padding-left: 5px;
}
.reports .row{
  margin-top: 30px;
}
.grtable .table .thead-dark th{
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  font-family: circular-bold,sans-serif;
  font-size: 16px;
  text-align: center;
  padding: 25px;
  color: #2e3e5f;
}
 .grtable .table td , .grtable .table th{
  font-family: circular-bold,sans-serif;
  font-size: 16px;
  color: #000;
}
.grtable{
  float: left;
  width: 100%;
  margin-bottom: 50px;
  margin-top: 50px !important;
}
.reports_in{
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.36);
    border-radius: 10px;
    padding: 25px;
    margin-left: 30px;
    margin-top: 50px;
    margin-bottom: 50px !important;
}
/* .grtable .table th, .grtable .table td{
   border: 0px;
} */
.grtable .table th, .grtable .table td{
  border: 0px;
  border-right: 1px solid  #ddd !important;
  padding: 15px;
  text-align: center;
}
.grtable table{
  border: 1px solid #ddd;
}
.sm_map p {
  font-family: circular-regular,sans-serif;
  color: #000;
  font-size: 16px;
}
.sm_map{
  padding-left: 20px;
}
.sm_map p:nth-child(2){
  margin: 0px;
  font-size: 22px;
}
.reports h2{
  color: #2e3e5f;
  font-family: circular-bold,sans-serif;
  margin-left: 30px;
}
.bt_align {
  float: right;
  width: 35%;
}
.bt_align button.btn.btn-primary{
  width: auto;
  min-width: 40%;
  margin-right: 20px;
  border-radius: 0px;
  padding: 12px 20px;
  font-family: circular-bold,sans-serif;
}
.bt_align button.btn.btn-primary:nth-child(1){
  background: #01b170;
  border-color: #01b170;
}
.bt_align button.btn.btn-primary:nth-child(2){
  background: #ff4d4d;
  border-color: #ff4d4d;
}
 .react-responsive-modal-modal{
  border-radius: 15px !important;
  width: 600px !important;
  max-width: 700px !important;
}
.cre_pop p{
  margin-bottom: 30px;
  color: #000;
}
.customModal button.react-responsive-modal-closeButton{
  display: block !important;
  top: 30px;
  right: 25px;
}
.customModal path{
  fill: #fff;
}
.cre_pop .form-control{
  height: 50px !important;
  border-color: #000000c9 !important;
}
textarea#exampleFormControlTextarea1 {
  height: auto !important;
  resize: none;
}
.cre_pop label{
  font-family: circular-bold,sans-serif;
  color: #000000bf;
  font-size: 18px;
}
.cre_pop_out h3{
  color: #fff;
  background: #293e69;
  padding: 30px;
  font-size: 18px;
  font-family: circular-bold,sans-serif;
}
.cre_pop {
  padding: 30px 40px;
}
.shadow_set p{
  margin: 0px 0px 50px 0px;
  font-size: 16px !important;
  color: #000;
}
.shadow_set .btn.btn-success{
  font-size: 18px;
  /* width: auto; */
  margin-top: 30px;
  padding: 12px 20px;
  height: auto;
  background: #01b170;
  line-height: initial;
  width: 170px;

}
.profile .row{
  padding-left: 50px;
}
.pr_pic {
  justify-content: center;
  display: flex;
}
.pr_pic > div{
  float: left;
  width: 65%;
  text-align: center;
}
.pr_pic ul{
  padding:0px;
  display: flex;
  max-width: 50%;
  margin: 0 auto;
}
.pr_pic ul li{
  padding-right: 15px;
}
.pr_pic ul li a{
  font-size: 36px;
}
.pr_pic ul li:nth-child(1) a{
  color: #4167b0;
}
.pr_pic ul li:nth-child(2) a{
  color: #03a9f5;
}
.pr_pic ul li:nth-child(3) a{
  color: #0078b7;
}

.shadow_set h4{
  font-family: circular-bold,sans-serif;
  color: #000;
  padding-bottom: 10px;
}
.col-lg-4.shadow_set{
  padding: 30px;
  max-width: 27%;
  margin: 0 auto;
  box-shadow: 0px -1px 6px 0px rgba(117, 113, 117, 0.35);
}
.shadow_set .img_set{
  margin: 50px 0px;
}
.assistence {
  margin-top: 50px;
  text-align: center;
}
.assistence p{
  font-size: 19px;
  margin-bottom: 50px;
}
.pr_form label{
  font-family: circular-bold,sans-serif;
  color: #000000b5;
  font-size: 16px;
}
.pr_form .form-control{
  height: 50px !important;
}
.col-lg-8.pr_form {
  box-shadow: 0px -1px 6px 0px rgba(117, 113, 117, 0.35);
  padding: 30px 50px;
  /* flex: 30% 1; */
  /* max-width: 30%; */
}
.col-lg-4.pr_pic {
  box-shadow: 0px -1px 6px 0px rgba(117, 113, 117, 0.35);
  padding: 50px 0px 70px 0px;
  flex: 30%;
  max-width: 30%;
  height: 500px;
  margin-right: 30px;
}
.pr_pic h5{
  font-size: 22px;
  font-family: circular-bold,sans-serif;
  letter-spacing: 1px;
  margin: 10px 0px;
}
.pr_titles{
  font-size: 18px;
  /* font-family: circular-regular; */
  /* font-weight: bold; */
  font-family: circular-bold,sans-serif;
}
.timing{
  float: left;
  margin-bottom: 10px;
  width: 100%;
}
.timing p {
  margin: 0;
}
.assistence h3{
  font-family: circular-bold,sans-serif;
  color: #000;
  margin-bottom: 25px;
}
.assistence .row{
  justify-content: space-between;
  margin: 0 auto;
}
.s_img {
  margin: 30px 0px;
}
.ags ul a {
  font-size: 16px;
  color: #2f3e5b;
  font-family: circular-bold,sans-serif;
  display: inline-block;
}
.ags{
  text-align: center;
  padding: 0px !important;
  border: 1px solid #ddd;
  max-width: 32% !important;
  margin-left: 50px;
}
.agreement {
  margin: 50px 0px;
}
 
.ags  h3{
  font-size: 22px;
  text-align: left;
  padding-bottom: 0px;
  height: 70px;
  background: #f0f0f0;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 50px;
  color: #000;
  font-family: circular-bold,sans-serif;
}
.col-lg-12.c_out {
  padding: 0px 20px 0px 30px;
  max-width: 87%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.pl_details_out {
  max-width: 64% !important;
  padding-top: 30px;
}
.pl_details_out h5{
  text-transform: uppercase;
  color: #000;
  font-family: circular-regular,sans-serif;
}
.invoice h5{
  font-size: 18px;
  font-family: circular-bold,sans-serif;
  color: #3c3c3c;
  padding-bottom: 10px;
}
.invoice ul{
  padding: 10px 50px;
  margin: 0px;
}
.invoice ul a{
  color: #3c3c3c;
}
.col-lg-6.invoice {
  border: 1px solid #ddd;
  padding: 0px;
  border-radius: 10px;
}
.invoice a {
  text-align: center;
  display: block;
  font-size: 18px;
  margin: 10px 0px;
  font-family: circular-bold,sans-serif;
  color: #7db8e1;
}
.invoice ul li , .invoice ul li a{
  display: flex;
  font-size: 16px;
    justify-content: space-between;
    width: 100%;
}
.invoice ul li {
  border-bottom: 1px solid #ddd;
  line-height: 30px;
  padding: 0px 30px;
}
.credentials .table .thead-dark th{
  background-color: #293e69;
    border-color: #293e69;
    font-family: circular-bold,sans-serif;
    border-right: 2px solid #fff;
}

.credentials h3{
  color:#000;
  letter-spacing: 0px;
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
  text-transform: capitalize;
  font-size: 20px;
}
.credentials p{
  font-size: 18px;
  color: #000;
}
.credentials {
  margin-top: 50px;
}
.credentials .btn.btn-success{
  border-radius: 5px;
  height: 50px;
  background: #01b170;
  display: flex;
  margin-bottom: 30px;
    margin-top: 30px;
  align-items: center;
  font-size: 18px;
  justify-content: center;
  line-height: 40px;
}
.credentials .table th, .credentials .table td{
  border: 1px solid #dddd;
  font-family: circular-regular,sans-serif;
  color: #000;
}
.invoice h3{
  text-align: center;
  text-transform: capitalize;
  background: #f2f9fe;
  padding: 22px 0px;
  /* font-family: OpanSansebold; */
  font-family: circular-bold,sans-serif;
  color: #000;
  font-size: 20px;
}
.in_pay{
  /* margin-left: 30px !important; */
  margin-top: 50px;
  margin-bottom: 50px;
}
.billing .row:nth-child(1){
  border: 1px solid #ddd;
  padding-bottom: 0px;
}
ul.pl_details li {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
  line-height: 36px;
  padding-top: 5px;
  font-family: circular-regular,sans-serif;
}
ul.pl_details{
  padding-left: 0px;
}
.billing .col-lg-4 {
  text-align: center;
}
a.cus_plan{
  text-transform: capitalize;
  font-size: 18px;
  border: 1px solid #ddd;
  height: 50px;
  padding: 12px 25px;
  margin-top: 0px;
  display: inline-block;
}
.c_out ul{
  padding: 0px;
  min-width: 300px;
  display: flex;
  justify-content: space-between;
}
.c_out ul li p:nth-child(1){
  color: #000;
  text-transform: capitalize;
  font-size: 18px;
  margin-bottom: 5px;
}
.c_out ul li:nth-child(2){
  font-family: circular-bold,sans-serif;
  font-size: 26px;
    color: #16a56f;
}
.billing .col-lg-4 h3{
  margin-bottom: 30px;
  margin-top: 50px;
  font-family: circular-regular,sans-serif;
  /*color: #000;*/
}
.billing  h1{
  width: 100%;
  /* height: auto; */
  font-size: 18px;
  padding: 20px 15px;
  background: #f2f9ff;
  display: block;
  height: 60px;
}
/* Client Dashboard */
.innrmobile-sidemenu i {
  color: #fff;
  display: none;
  font-size: 16px;
}

/* Admin Dashboard */
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.name_set{
  font-size: 13px;
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
}
.user_name{
 
  margin-bottom: 0px;
  margin-right: 15px;
  display: inline-block;
}
.user_name span{
  display: block;
  width: 100%;
  color: #000;  
}
/* #sidebar {  
    width: 100%;
    margin-right: 35px;
    padding: 0px 0px 0px 0px;
} */
a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
#sidebar {
  /* don't forget to add all the previously mentioned styles here too */
  width: 100%;
  margin-right: 35px;
  padding: 0px 0px 0px 0px;
  background: #293e69;
  height: 85vh;
  overflow-x: hidden;
  overflow-y: scroll;
  color: #fff;
  transition: all 0.3s;
}

#sidebar .sidebar-header {
  padding: 20px;
  border-bottom: 1.5px solid #ddd3;
  background: #293e69;
}

#sidebar ul.components {
  padding: 15px 0;
  margin-left: 15px;
  border-bottom: 1px solid #293e69;
}
.bg-light {
  background-color: #fff !important;
}
.work_outs , .questions_outs{
  position:relative;
}
.all_ques_in:last-child {
    border: 0px;
}
.container-fluid.footer {
     padding-left: 0px;
     display: inline-block;
     width: 100%;
     position: relative;
     margin-top: 50px;
}
footer {
  background-color: #05102e;
  padding: 50px 0px 0px !important;
  margin-top: 0px !important;
  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
}
.copy_rights p {
  margin-bottom: 10px;
}
.about_detail .container-fluid.footer{
  position: relative;
  margin-bottom: 7px;
  float: left;
  width: 100%;
  margin-top: 15px;
}
#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  font-size: 16px;
  padding: 10px;
  display: block;
  font-family: circular-bold,sans-serif;
  color: #fff;
}
.ab_border {
  margin-top: 20px;
  position: relative;
  padding-top: 10px;
}
.ab_border::before{
  content: '';
  border: 0.1px solid #fff;
  position: absolute;
  width: 100%;
  left: -10px;
  top: -10px;
  
}
#sidebar ul li a:hover {
  background-color: #fff;
  color: #293e69 !important;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

#sidebar ul li.active > a, a[aria-expanded="true"] {
  /* color: #fff; */
  background: #fff;
  color: #293e69;
}
.v_back{
  -webkit-box-shadow: 15px 15px 0px 0px rgba(41,62,105,1);
  -moz-box-shadow: 15px 15px 0px 0px rgba(41,62,105,1);
  box-shadow: 15px 15px 0px 0px rgba(41,62,105,1);
}
.play_set{
  position: absolute;
  top: 40%;
  left: -34px;
}
.play_set .p_button{
  border: 0px;
  width: 70px;
  height: 70px;
  background: #00B170;
    color: #fff;
}
.navbar-light .navbar-brand {
  margin-left: 10%;
  margin-left: 0;
  padding: 15px 0px 15px 40px;
  background: #fff;
  width:auto;
      min-width: 150px;
}
#sidebar ul li a.active{
  background-color:#fff;
  color: #293e69;
  border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}
.navbar {
  padding: 0;
}
.list-unstyled img{
  width: 30px;
    padding-right: 10px;
}
ul.pricing-content.list-unstyled{
  width: 78%;
  margin: 0 auto;
}
.side_out{
  position: relative;
  z-index: 9999;
  height: 100%;
}
.set_adheader{
  width:100%;
  padding: 0px 50px;
}
.play_set .fa-play{
  font-size: 32px;
}
.service_desc p{
  width: 100%;
  margin: 0 auto;
  font-size: 16px;
}
.lang_select span {
  color: #fff;
  margin-right: 0px;
  padding-right: 0px;
}
/* .lang_select span::after {
  content: '\f078';
  font-family: 'fontawesome';
  font-size: 12px;
  font-weight: 300;
  position: absolute;
  right: 0;
  top: 13px;
  transition: 0.3s;
}
.lang_select span:hover:after {
  transform: rotate(180deg);
} */
.lang_select i {
  color: #fff;
  font-size: 14px;
  padding-right: 5px;
}
.lang_select {
  position: relative;
  padding: 10px 0px;
  width: 75px;
}
.lang_select ul li {
  color: #000;
  display: block;
  padding: 3px 0px !important;
  cursor: pointer;
}
.lang_select ul li:nth-child(3) {
  background: transparent;
}
.lang_select ul li:hover {
  color: #01b170;
}
.lang_select ul {
  position: absolute;
  background: #fff;
  width: auto;
  display: block;
  top: 42px;
  padding: 2px 20px;
  z-index: 1;
  opacity: 0;
  display: none;
  box-shadow: 0 2px 8px 0 hsl(0deg 0% 44% / 40%);
  border-radius: 5px;
  transition: all 0.3s ease;
}
.lang_select ul:before {
    content: "";
    position: absolute;
    width: 0;
    top: 5px;
    height: 0;
    border-color: transparent transparent #fff #fff;
    border-style: solid;
    border-width: 6px;
    transform-origin: 0 0;
    transform: rotate(135deg) translateX(-50%);
    left: 40%;
    box-shadow: -2px 2px 2px 0 hsl(0deg 0% 44% / 20%);
}
.lang_select ul.active {
  opacity: 1;
  display: block;
}

ul.sub_service , .sub_membership{
  position: absolute;
  padding: 0px;
  background: #fff;
  width: 600px;
  z-index: 999;
  left: -50px;
  box-shadow: 0 2px 8px 0 hsla(0,0%,43.9%,.4);
  top: 72px;
  border-radius: 10px;
  padding: 10px;
}
.nav-item ul li a {
  border-bottom: 1px solid #ddd;
  padding: 10px 0px !important;
  min-width: 175px;
}
.sub_service ul li:nth-child(4) a {
  border-bottom: none;
}
.sub_service li ul{
  padding:0px;
  float: left;
  width: 33%;
}
.cus-widt_10 {
  width: 10%;
}
.sub_service li{
  /* max-width: 190px; */
  /* float: left; */
  padding-left:10px;
}
#contact_forms select[name='service'] {
  height: 75px !important;
}
.seo_services{
  /*padding-left: 100px;*/
  float: left;
  padding-top: 50px;
}
.sub_service , .sub_membership{
  display: none;
}
/* .service:hover .sub_service{
  display: block;
} */
.seo_services h4{
  font-family: circular-bold;
  color: #293e69;
  margin-bottom: 20px;
}
.seo_services p{
  width: auto;
  font-size: 18px;
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
  float: left;
  line-height: 36px;
}
.seo_services .input-group{
  width: 80%;
}
.seo_services .input-group-append{
  margin-left: 15px;
}
.seo_services .input-group-append button{
  height: 50px;
  background: #00B170 !important;
  width: 200px;
  font-family: circular-bold;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  border-color: #00B170;
}
.seo_services input{
  height: 50px !important;
  font-family: circular-bold;
  color: #000 !important;
  font-size: 18px;
  padding-left: 20px;
}
.web_link{
  margin-top: 30px;
  float: left;
  width: 100%;
}


.price_up_in{
  justify-content: space-around;
  /* max-width: 80%; */
  margin: 0 auto;
}
.pr_value{
  font-family: circular-regular !important;
  font-size: 24px !important;
  color: #3AA9FF !important;
}
.fa-check{
  color: #3AA9FF !important;
}
.pricing {
  position: relative;
  margin-right: 5px;
  margin-bottom: 15px;
  box-shadow: 1px -1px 10px 0px #ddd;
  margin-left: 5px;
  cursor: pointer;
}
.pricing:hover{
  transform: scale(1.2); 
  transition: all 0.5s ease-in-out;
}

.pricing-active {
  /* transform: scale(1.2); */
  background: #fff;
  position: relative;
  z-index: 999;
  border: 1px solid #ddd;
}

/* .pricing:hover {
   
} */

.pricing:hover h4 {
  color: #36d7ac;
}

.pricing-head {
  text-align: center;
}

.pricing-head h3,
.pricing-head h4 {
  margin: 0;
  line-height: normal;
}

.pricing-head h3 span,
.pricing-head h4 span {
  display: block;
  margin-top: 5px;
  font-size: 14px;
  font-style: italic;
}

.pricing-head h3 {
  font-weight: 300;
  color: #000;
  padding: 12px 0;
  font-size: 18px;
  font-family: circular-bold;
}

.pricing-head h4 {
  color: #bac39f;
  padding: 5px 0;
  font-size: 54px;
  font-weight: 300;
  background: #fbfef2;
  border-bottom: solid 1px #f5f9e7;
}

.pricing-head-active h4 {
  color: #36d7ac;
}

.clear-results {
  float: right;
    font-size: 16px;
    background: #3885c1;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;
}
.clear-span{
  float: right;
  padding: 5px
}
.pricing-head h4 i {
  top: -8px;
  font-size: 28px;
  font-style: normal;
  position: relative;
}

.pricing-head h4 span {
  top: -10px;
  font-size: 14px;
  font-style: normal;
  position: relative;
}

/*Pricing Content*/
.pricing-content li {
  color: #000;
  font-size: 14px;
  padding: 4px 15px;
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
   
}

/*Pricing Footer*/
.pricing-footer {
  color: #777;
  font-size: 11px;
  line-height: 17px;
  text-align: center;
  padding: 0 20px 19px;
}

/*Priceing Active*/
.price-active,
.pricing:hover {
     z-index: 9999999;
    background: #fff;
}

tr.camp_list .first_campllist{
  background: white;
  /* transition: 0.2s ease-in;
  color: #fff; */
}

tr.camp_list .first_campllist:hover{
  background: #3986c1;
  /* transition: 0.2s ease-in;
  color: #fff; */
}

tr.camp_list:hover {
  background: #3986c1;
  transition: 0.2s ease-in;
  color: #fff;
}
.price-active h4 {
  color: #36d7ac;
}
.camp_list{
  cursor: pointer;
}

.no-space-pricing .pricing:hover {
  transition: box-shadow 0.2s ease-in-out;
}

.no-space-pricing .price-active .pricing-head h4,
.no-space-pricing .pricing:hover .pricing-head h4 {
  color: #36d7ac;
  padding: 15px 0;
  font-size: 80px;
  transition: color 0.5s ease-in-out;
}

.yellow-crusta.btn {
  color: #FFFFFF;
  background-color: #3AA9FF;
  width: 120px;
  margin-top: 20px;

}
.yellow-crusta.btn:hover,
.yellow-crusta.btn:focus,
.yellow-crusta.btn:active,
.yellow-crusta.btn.active {
    color: #FFFFFF;
    background-color: #cfa500;
}

.price_up {
  margin-bottom: 75px;
  margin-top: 100px;
}
.upgrade_set .container-fluid.footer{
  position: relative;
  margin-bottom: 15px;
  float: left;
}
.published-datashort {
  text-align: right;
}
.published-datashort select {
  border: none;
  margin-left: 15px;
  font-size: 16px;
}
.published-datefilter .react-date-picker__inputGroup {
  display: none;
}
.published-postlist table {
  background: #fff;
}
.published-postlist .table-responsive{
  height: 500px;
}
.published-postlist table .published-channelname i {
  background: #0f5199;
  color: #fff;
  padding: 8px 10px 7px 10px;
  margin-right: 10px;
  border-radius: 50%;
}
.published-datefilter .react-date-picker {
  display: -webkit-box;
  float: right;
  width: 70px;
}
.postdet-scheduled {
  margin-top: 25px;
}
.scheduledpost-blog {
  margin-bottom: 30px;
}


@media only screen and (max-width: 1300px) {
  .container{
    max-width:1261px;
    width:auto;
  }
  .navbar-light .navbar-nav .nav-link{
        padding: 10px 0px !important;
  }
  .set_dots ul{
        width: 465px;
  }
}
@media only screen and (max-width: 993px) {
  .navbar-light .navbar-nav .nav-link{
       font-size:14px;
  }
  .navbar-light .navbar-brand{
        padding: 15px 0px 15px 10px;
  }
}
@media only screen and (max-width: 767px) {
   
  .memcontent , .videosPlayer{
            margin: 50px 0px 0px;
  }

  .t_bar {
    font-size: 14px;
  }

  .navbar-light .navbar-nav .nav-link{
        font-size: 14px;
          margin-left: 10px;
  }
  li.nav-item{
    margin:0px;
  }
  .navbar-light .navbar-nav .nav-link{
        font-size: 16px;
  }

   .sm_set {
       margin-bottom: 50px;
   }
   .t_bar ul{
    width: 235px;
    display: block;
    margin: 0 auto;
    float: initial;
  }
  .t_bar li{
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .postdet-commendbox-icon {
    text-align: right;
  }
  .video-popup .pop-content .close-btn {
    right: 0px !important;
  }
  .modal-video-inner {
    padding-top: 50px !important;
  }
  .asopayment-ptb-50 form .radio {
    display: block !important;
  }
  .asopayment-ptb-50 form {
    padding: 25px !important;
    margin-bottom: 30px;
  }
  .services p {
    min-height: 0px;
  }
  .services .mbl_view .slick-track {
    padding: 35px 0px;
  }
  .bsnvrt_catboxcol_gybox .bsnvrt_catboxcol_gy:nth-child(odd) {
    padding: 20px !important;
  }
  .bsnvrt_catboxcol_gybox .bsnvrt_catboxcol_gy:nth-child(even) {
    padding: 20px !important;
  }
  .bsnvrt_catboxcol_wtbox .bsnvrt_catboxcol_wt:nth-child(odd) {
    padding: 20px !important;
  }
  .bsnvrt_catboxcol_wtbox .bsnvrt_catboxcol_wt:nth-child(even) {
    padding: 20px !important;
  }
  .bsnvrt_catboxcol_wtbox {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .bsnvrt_catboxcol_gybox {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .modal-video-movie-wrap iframe {
    padding-left: 10px;
    padding-right: 10px;
  }
   .hv_content{
    padding-left: 0;
   }
   .join_now{
     margin:0px;
   }
   .work_banner{
         height: 70vw !important;
   }
   /* section.membership_program{
    margin-top: 50px !important;
   } */
   .navbar-nav{
    position: absolute;
    z-index: 999;
    background: #fff;
    width: 100%;
  
  }
  .navbar-light .navbar-brand {
    padding: 15px 0px 15px 15px;
  }
  .header_back{
    padding:0px;
  }

  .navbar-light .navbar-toggler{
      margin-right: 15px;
  }
   .b2bcontents{
     width:100%;
   }
   .rnd_section{
     max-width:100%;
         flex: inherit;
   }
   .banner_counts ul li{
         width: 160px;
           height: 160px;
   }
   .banner_countsIn h2{
         font-size: 24px;
   }
   .sub_service li ul {
     width: 100%;
   }

   .slider_out {
    height: 300px;
  }

  .testimonial_slider_container {
    height: auto;
  }

  .testimonial_slider_container .testimonial_slider {
    min-height: none;
    position: relative;
    left: 0 !important;
    right: 0 !important;
    transform: translateX(0) translateY(0) !important;
  }

  .testimonial_set {
    height: auto;
  }

  .testimonial_slider .slick-prev:before, .testimonial_slider .slick-next:before{
    color: #6e6e6e;
    opacity: 1;
  }

  .testimonial_slider .slick-prev {
    left: 5px;
  }
  
  .testimonial_slider .slick-next {
    right: 5px;
  }

  /* our services title */
  .our-services-title {
    display: none;
  }
   
  /* online course */
  /* .online_course {
    margin: 86px 0 !important;
  } */

}
@media only screen and (max-width: 481px) {

  header .title-section{
    display: block !important;
    width: 100%;
  }
  header .title-container{
    margin: 10px 0px;
  }
  .cus_price{
    position: fixed;
    top: 160px;
    z-index: 999;
    right: 0;
  }

  /* .bu_set{
    float: left !important;
  } */
  /* .resend_otp{
    margin: 10px 0px;
  } */
  .otp-submit{
    float: left;
  }
  .b2bcontents h4{
    font-size: 18px;
    line-height: 30px;
  }
  .h_we_work .contact_banner{
    height: 70vw;
  }
 
  .join_ez li h3{
    font-size: 14px;
  }
  .whyjoin h1, .join_ez h1, .social h1{
    font-size: 28px !important;
  }
  .container-fluid.register-details.details-box, .container-fluid.find-details.details-box, .container-fluid.support-details.details-box, .container-fluid.growing-details.details-box{
        min-height: auto !important;
    max-height: inherit !important;
  }
  .v_set.video3{
    left:0px !important;
  }
  
  .join_now{
        font-size: 18px;
          padding: 10px 50px;
  }
  .banner_counts ul{
             display: block;
    float: initial;
    width: 100%;
    margin: 0 auto;
  }
  .banner h1{
        margin: 10px 0px;
          font-size: 26px !important;
  }
  section.container-fluid.banner {
    padding-bottom: 30px;
  }
  .banner_counts ul li{
        padding-top: 5px;
        width: 160px;
          height: 160px;
        float: left;
    display: block;
            margin-bottom: 10px;
  }
  .banner_counts ul li:nth-child(1){
        float: inherit;
    margin: 0 auto;
  }
  .banner_counts ul li:nth-child(2){
    float: initial;
    overflow: hidden;
    margin: 15px auto;
  }
  .banner_counts ul li:nth-child(3){
        text-align: center;
    margin: 0 auto;
    float: initial;
    overflow: hidden;
  }
  .banner_countsIn h2{
        font-size: 22px;
  }
  .banner_counts ul:before{
    display:none;
  }
  .service_banner {
        height: 300px;
          background-position: center;
  }
  .b_content h3 , .b_content h1{
        font-size: 20px;
  }
  .b_content{
    margin:0px;
  }
  #b2bimg{
        padding: 30px 15px;
  }
  .b2bcontents p{
        font-size: 18px;
  }
  .videosPlayer {
    margin: 0px 0px 0px;
  }
  
  section.change_service.service_desc h2{
        font-size: 24px;
  }
  section.change_service.service_desc{
        margin-top: 50px;
  }
  .footer-info-contact .ex_icon{
        left: 15px;
  }
  .footer-info-contact{
        padding-left: 0px;
  }
  .videosection .col-lg-6{
    padding:0px;
  }
}

/* how-we-work-with styles starts */
.work-banner-container {
  padding: 3rem 0;
}

.btn-round-new {
  border-radius: 40px !important;
}

.btn-round-new:hover {
  color: #fff;
}

.btn-round-new-custom {
  padding: 10px 24px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.work-banner-container h1 {
  font-family: circular-bold;
  font-size: 35px;
  text-align: center;
  padding: 16px 0 20px;
}
.slider-banner-container {
  padding: 10px 0 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}
.work_banner { 
    height: 520px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.content_box {
  margin: 0px 0 0 0px;
  width: 92%;
}
.content_box h3 {   
    font-family: circular-bold;
    font-size: 70px;
    margin: 10px 0px;
    text-transform: uppercase;
}
.content_box h5 {   
    font-family: circular-bold;
    font-size: 1.5vw;
      line-height: 30px;
    margin-bottom: 40px;
}
.content_box p {
    font-size: 1.07vw;
  line-height: 30px;
    font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
}
.para {
   padding-left: 50px;
}
.work-heading {
  text-align: center;
  color: #293e69;
  font-family: circular-bold;
}
.slider-container {
  text-align: center;
  padding: 0 85px;
}
.slider-container .slick-slide img {
  display: inline-block;
  /*width: 170px;*/
}
.slider-content p {
  font-size: 16px;
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
  margin: 1rem 0 2rem; 
}

.slider-left-content h4 {
  font-family: circular-bold;
  font-size: 18px;
  line-height: 1.5;
}    
.slider-left-content h5,p,a{
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
}
.slider-left-content p {
  margin-bottom: 2rem;
  line-height: 1.8;
  font-size: 15px;
}
.left-col {
  width: 76%;
  padding-top: 10px;
}
.slider-left-content a{
  background-color: #00B170;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 1rem;
}
.slick-dots li {
  margin: 0 -2px !important;
}

.slick-dots li button:before {
  border: 2px solid #1e90ff;
  border-radius: 100%;
  width: 12px !important;
  height: 12px !important;
  color: transparent !important;
}
.slick-dots li.slick-active button:before{
  color: transparent !important;
}
.bg-box {
  background: #e9e9e9;
  height: 100% !important;
  width: 100% !important;
  float: left !important;
  color: #000 !important;
}
.latest_blog h3{
  text-align: center;
  float: left;
  width: 100%;

}
.container.work-banner-container{
  overflow: hidden;
}
@media only screen and (max-width: 639px) {
  .left-col {
    text-align: center;
    width: 100%;
    padding: 10px 15px 0 15px;
  } 
  .slider-banner-container {
    flex-flow: column;
  }
  .slider-container {
    padding: 0 15px;
  }
  .slider-left-content {
    padding: 0 15px 0 15px;
  } 
  .slider-content p {
    margin: 1rem 15px 2rem;
  }
  .work-banner-container h1 {
    font-size: 18px;
  }
  .content_box {
    margin: 0;
  }
  .content_box h3 {
    font-size: 7.2vw;
    margin-bottom: 15px; 
  }
  .content_box h5 {
    font-size: 3.6vw; 
    margin-bottom: 15px;
  }
  .content_box p {
    font-size: 3vw; 
    margin-bottom: 15px;
  }
  .para {
    transform: none;
    padding: 0 0 0 8vw;
  }
  .work-heading {
    font-size: 25px;
    margin-top: 30px;
  }
  .bg-box .slider-container .slick-slide img {
    margin-top: 0px;
  }
  .bg-box  .slider-banner-container {
    padding: 0;
  }
  
}
@media only screen and (min-width: 640px) and (max-width: 1200px) {
  .content_box {
    margin: 12vw 0 0;
  }
  .slider-container .slick-slide img {
    width: 20vw;
  }
  .para {
    transform: translateX(3vw);
  }
  .content_box h3 {
    font-size: 3.5vw;
    margin-bottom: 4vw;
  }
  .content_box h5 {
    font-size: 1.8vw;
    margin-bottom: 4vw;
  }
  .content_box p {
    font-size: 1.5vw;
  }
  .work-banner-container h1 {
    font-size: 2.8vw;
  }
  .work-heading {
    font-size: 3vw;
  }
  .work-banner-container {
    padding: 2rem 0;
  }
  .slider-container {
    width: 50%;
    padding: 0 15px;
  }
  .slider-content p {
    font-size: 1.57vw;
    margin: 1rem auto 1.5rem;
    width: 40vw;
  }
  .slider-left-content {
    padding: 0 0 0 7vw;
  }
  .bg-box .slider-left-content {
    padding: 0 0 0 7vw;
  }
  .slider-left-content p {
    font-size: 1.6vw;
    margin-bottom: 3.2vw;
  }
  .slider-left-content h4 {
    font-size: 2.1vw;
  }
  .left-col {
    width: 37vw;
    padding-top: 0px;
  }
  .slider-left-content a{
    padding: 1vw 2vw;
    font-size: 1.5vw;
  }
  .slider-banner-container {
    padding: 1.7vw 0 0;
  }
}


/* how-we-work-with styles ends */


/* contact styles starts */
.container.contact_form {
    margin-top: 50px;
}
p.success{
  text-align: center;
  color: green;
}
.contact-left-col ul li span , .contact-left-col ul li a{
      font-family: circular-bold;
    font-size: 15px;
}
.contact-left-col .col-md-12{
  margin-bottom:30px;
}
.col-md-7.col-lg-8.contact-right-col{
      max-width: 55%;
    margin: 0 auto;
}
.contact_banners .banner_content{
  background-size: cover;
  min-height: 0px;
  display: block;
  align-items: center;
}
.contact_banner { 
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
 background-color: #EBEDEF;
}
.contact_banners{
  background-repeat: no-repeat;
  background-size: 55% 100%;
  position: relative;
  color: #fff;
  display: block;
  align-items: center;
  justify-content: flex-start;
 background-color: #EBEDEF;
 padding: 100px 0px;
}
.contact-right-col, .contact-left-col {
  margin-bottom: 50px;
}
.contact-left-col {
  padding-top: 25px;
  padding-left: 60px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.contact-map-banner { 
  background-color: #f5f5f5;
  text-align: center;
  padding: 100px 0 50px;
}
.contact-map-banner h3{ 
     transform: translateY(-45px);
    color: #000;
    font-family: circular-bold;
    font-size: 32px;
    letter-spacing: 1px;
    padding-bottom: 0px;
    padding-top: 0px;
}
.content { 
  
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  color: #000;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-position: center;
}
.banner_content {
  padding: 0 0 0 5vw;
}
.banner_content h3 {
  font-family: circular-bold;
  font-size: 5vw;
  margin-bottom: 5vw;
}
.banner_content p{
      line-height: 36px;
}
.banner_content p { 
  font-family: circular-regular;
  font-size: 3vw;
}
.contact_form .form-control {
  background-color: #e9f1fe;
  border: 1px solid #e9f1fe;
      height: 45px;
}
.btn-primary {
  background-color: #01b170;
  border-color: #01b170;
}
.btn-primary:hover {
  background-color: #25936a;
  border-color: #25936a;
}
.form-padding {
  padding: 30px 30px;
}
.form-padding h2 {
  text-align: center;
  color: #293e69;
  font-family: circular-bold;
  margin-bottom: 45px;
  font-size: 25px;
}
.col-form-label {
  font-family: circular-bold;
}
.contact-left-col h4{
  font-family: circular-bold;
  font-size: 20px;
      color: #293e69;
  position: relative;
}
.contact_form textarea.form-control{
      min-height: 172px;
    resize: none;
}
.contact-left-col ul li a{
 color: #000000;
}
.contact-left-col ul {
  padding-left: 0;
 }
.contact-left-col ul li {
  font-family: circular-regular;
  font-size: 1rem;
  line-height: 2.5;
  position: relative;
 }
 
.contact-left-col h4:after{
  width: 30px;
  content: " ";
  background-image: url('../src/images/india.png');
  background-repeat: no-repeat;
  position: absolute;
  left: 65px;
  top: 0;
  height: 21px;
}
.contact_form button.btn.btn-primary {
    border-radius: 0px;
    min-width: 150px;
      font-family: circular-bold;
}
.shadow-lg {
    box-shadow: 0 0rem 14px rgba(0, 0, 0, 0.175) !important;
      border-radius: 8px !important;
}
.contact-left-col ul li:before{
  width: 15px;
  content: " ";
  background-image: url('../src/images/contact-mail.png');
  background-repeat: no-repeat;
  position: absolute;
  left: -23px;
  top: 11px;
  height: 20px;
}
.contact-left-col ul li:nth-child(1):before{
  background-image:url('../src/images/contact-landmark.png')
}
.contact-left-col ul li:nth-child(3):before{
  background-image:url('../src/images/contact-phone.png')
}
.contact-left-col ul {
  padding-left: 26px;
  width: 270px;
}
.contpg-banerlogo-i2 {
  padding-left: 20px;
}

@media only screen and (min-width: 1200px) {
  
  .banner_content {
    padding: 0 0 0 125px;
  }
  .banner_content h3 {
    font-size: 40px;
    margin-bottom: 25px;
  }
  .banner_content p { 
    font-size: 1rem;
  }
  .contact-left-col ul li {
    line-height: 2;
  }  
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .banner_content {
    padding: 0 0 0 10vw;
  }
  .contact-left-col{
      padding-left: 0;    
   }
  .banner_content h3 {
    font-size: 3.5vw;
    margin-bottom: 4vw;
  }
  .banner_content p { 
    font-size: 2vw;
  }
  .contact-left-col ul {
    width: 100%;
  }
  .contact-left-col ul li:before {
    top: 12px;
  }
  .contact-left-col ul li {
    line-height: 2.1;
  }
}
@media only screen and (max-width: 993px) {
  .form-padding {
    padding: 15px 19px;
  }
  .contact-left-col {
    padding-left: 25px;
  }
  .contact-left-col ul li {
    font-size: 2vw;
    line-height: 2.1;
  }
  .col-form-label {
    font-size: 13px;
  }
}
@media only screen and (max-width: 767px) {
  .contact-left-col ul li{
        font-size: 3vw;
  }
  .col-md-7.col-lg-8.contact-right-col{
        max-width: 80%;
  }
  .contact-left-col{
    max-width: 300px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 481px) {
  .contact-left-col ul li{
        font-size: 4vw;
  }
  .col-md-7.col-lg-8.contact-right-col {
    max-width: 100%;
  }
  .contact-left-col {
    padding-left: 15px;
  }
}



/* contact styles ends */

/* register styles start */
.register_image.details-image , .support_image.details-image{
      padding-right: 100px;
        padding-bottom: 100px;
    padding-top: 50px;
      height: 350px;
      transition: all 0.5s ease;
  

}
.nxt_arrow{
      position: absolute;
    top: 23%;
    right: 20%;
      font-size: 24px;
}
img.findlarge {
    max-width: 300px !important;
}
.grow_large{
   max-width: 180px !important;
}
.regimge , .findimge , .growimge , .supimge{
      width: 50px;
    height: 50px;
    text-align: center;
    padding: 12px 0px;
    background: #d8d8ff;
    border-radius: 100%;
}
.findimge{
      background: #f3ffbc;
}
.growimge{
      background: #ececec;

}
.supimge{
      background: #f8d2ce;
}
.details-image:hover{
      cursor: pointer;
}
.find_image.details-image  , .growing_image.details-image{
    padding-left: 100px;
      padding-bottom: 100px;
    padding-top: 50px;
      height: 350px;

}
.register-container .row-content {
  text-align: center;
   
}
.details-image {
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  
}
.details-image img {
  width: 180px; 
}
.growing_image { 
  margin-right: 55px;
}
.support_image {
  margin-left: 85px;
}
.icons {
  position: absolute;
  top: 0;
}
.icons img{
  max-width: 50px;
}
.busver_head {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 0px;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.container-fluid.register-container {
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 0px;
}
.register_icon {
      right: 35%;
    top: 23%;
}
.register_image:before , .find_image:before , .growing_image:before , .support_image:before , .register-cont:before{
  content: "";
  position: absolute;
  z-index: -1;
  top: -300px;
  left: -100px;
  right: 0;
  bottom: 0;
  border-radius: 80%;
  background: #d8d8ff;
  width: 0%;
  height: 0%;
    overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.register-cont:before {
  transition: none;
}

.register-cont.register::before, .register-cont.find::before, .register-cont.support::before, .register-cont.grow::before {
  background: #fff;
      content: "";
    position: absolute;
    z-index: -1;
    top: -300px;
    left: -100px;
    right: 0;
    bottom: 0;
    border-radius: 80%;
    background: #ffffff;
    width: 0%;
    height: 0%;
      overflow: hidden;
    /* transition: all 0.5s ease-in-out; */
  
}
.register-cont{
      position: relative;
    overflow: hidden;    
}
.register-cont.register:before , .register-cont.find:before , .register-cont.grow:before , .register-cont.support:before{
       width: 100%;
    height: 100%;
    transform: scale(2);
    top: 0;
    border-radius: 0px;
}
.register-cont.register:before {
  background: #fff;
}
 .register-cont.find:before {
    background: #fff;
 }
 .register-cont.grow:before {
  background: #fff;
 }
 .register-cont.support:before {
  background: #fff;
 }
.find_image:before{
  left: auto;
  background: #f3ffbc;
  right:-100px;
}
.details-box .row {
  width: 100%;
  max-width: 800px;
  margin-top: 50px;
}
.growing_image:before{
  top:auto;
  right:-220px;
  left:auto;
  background:#d8d8d8;
  bottom:-210px;
}
.support_image:before{
  top:auto;
  right:auto;
  left:-240px;
  background:#f8d2ce;
  bottom:-230px;
}
.icons{
  cursor:pointer;
}
.register_icon:hover .register_image:before , .find_icon:hover:before , .support_icon:hover:before , .grow_icon:hover:before{
   width: 800px;
    height: 650px;
    transform: scale(1);  
}
.register_icon .regimge, .support_icon .supimge, .find_icon .findimge, .grow_icon .growimge {
  position: relative;
}
.regimge::before, .supimge::before, .findimge::before, .growimge::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  height: 50px;
  width: 50px;
  background: #333;
  border-radius: 50%;
  z-index: -1;
  opacity: 1;
  animation: shdwefct 1s linear infinite;
  opacity: 0.3;
}
@keyframes shdwefct {
  0%   {height: 50px; width: 50px; opacity: 0.3;}
  100%  {height: 80px; width: 80px; opacity: 0;}
}
.register_image.active:before , .find_image.active:before, .growing_image.active:before , .support_image.active:before{
  width: 800px;
  height: 650px;
  transform: scale(1);
}
.register_icon:hover .regimge{
  background: #fff;
  transition: all 0.5s ease-in-out;
}
.find_icon:hover .findimge{
      background: #fff;
     transition: all 0.5s ease-in-out;
}
.grow_icon:hover .growimge{
      background: #fff;
     transition: all 0.5s ease-in-out;
}
.support_icon:hover .supimge{
      background: #fff;
     transition: all 0.5s ease-in-out;
}

/* .register_icon:hover {
  cursor: pointer;
  transform: matrix(1, 0, 0, 1, 0, 0);
  background-color: rgb(255, 239, 195);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  display: inline-block;
} */
.find_icon {
  left: 35%;
  top: 23%;
}
.support_icon {
      right: 35%;
    top: 10%;
}
.grow_icon {
 left: 35%;
    top: 10%;
}

.row-content span {
  display: block;
  color: #293e69;
  font-family: circular-bold;
  font-size: 13px;
  padding: 7px 0;
  border-bottom: 2.5px solid#bf4053;
}
.register-container {
  position: relative;
      overflow: hidden;
}
.text {
  position: absolute;
  top: 28%;
  left:38%;
}
.text h1 {
  font-family: circular-bold;
  font-size: 50px;
}
.text p {
  text-align: center;
  font-family: circular-regular;
  font-size: 15px;
}

/* register styles ends */


 
 .container-fluid.register-details.details-box , .container-fluid.find-details.details-box , .container-fluid.support-details.details-box , .container-fluid.growing-details.details-box{
   
   min-height:0px;
    max-height:670px;
    overflow:hidden;
    position:relative;
 }
.details-box {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 50px 0;
}
.details-box img {
  max-width: 500px !important;
  width: 250px;
  height: auto;
  margin: auto;
  display: block;
}
.details-box a {
    background-color: #00B170;
    padding: 3px 9px;
    color: #ffffff;
    font-size: 14px;
    border-radius: 4px;
    display: inline-block !important;
}
.details-box h2 {
  font-family: circular-bold;
  font-size: 20px;
  line-height: 2;
  border-bottom: 2.5px solid #bf4053;
  margin: 40px 0 14px;
}

.details-box ul {
  counter-reset: section;
  list-style: none;
  margin-top: 20px;
}

.details-box li {
  font-family: circular-regular;
  font-size: 14px;
  line-height: 2;
  position: relative;
  margin: 0 0 10px 0;
}

.details-box li:before {
  content: counter(section);
  counter-increment: section;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 20px 0 0;
  background-color: #fff;
  border-radius: 100%;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
}

@media only screen and (min-width: 640px) and (max-width: 1023px) {
  .register_image.details-image, .support_image.details-image{
    padding:0px;
        height: 240px;
  }
  
  .icons img {
    max-width: 7vw;
  }
  .row-content span {
    font-size: 2vw;
  }
  .register_icon {
    right: 6vw;
  }
  .details-image img {
    width: 28vw;
  }
  .register_image img {
    width: 22vw;
  }
  .find_icon {
    left: 10vw;
  }
  .support_icon {
    right: 11vw;
  }
  .details-image {
    height: 40vw;
  }
  .register_image {
    margin-left: -5vw; 
  }
  .growing_image { 
    margin-left: 9vw;
  }
  .support_image {
    margin-left: 5vw;
  }
  .find_image {
    margin-left: 7vw;
  } 
  .register_icon, .find_icon, .support_icon, .grow_icon {
    top: 7vw;
  }
  .register_icon {
    right: 6vw;
  } 
  .find_icon {
    left: 12vw;
  } 
  .support_icon {
    right: 14vw;
  }
  .grow_icon {
    left: 10vw;
  }
  .text {
    top: 24vw;
    left: 35vw;
  }
  .text h1 {
    font-size: 4vw;
  }
  .text p {
    font-size: 1.8vw;
  }
}

@media only screen and (max-width: 639px) {
  .register_image.details-image, .support_image.details-image , .find_image.details-image, .growing_image.details-image{
    padding:0px;
        height: 240px;
  }
  .details-box Ul {
    padding: 0 8vw 0 18vw;
  }
  .details-image {
    width: 160px;
  }
  .icons img {
    max-width: 45px;
  } 
  .row-content span {
    font-size: 12px;
  }
  .register_icon {
    right: 1vw;
    top: 13vw;
  } 
  .find_icon {
    left: 11vw;
    top: 7vw;
  }
  .support_image img {
    margin-left: 0;
  }
  .support_icon {
    right: 8vw; 
  }
  .growing_image img {
    margin-right:0;
  } 
  .grow_icon {
    left: 14vw;
    top: 7vw;
  }
  .text {
    top: -51vw;
    left: 15vw;
  } 
  .register-container {
    margin-top: 0;
  }
  .register-container .row-content {
    padding-bottom: 30px;
  } 
  .growing_image {
    margin: 3vw auto 5vw;
  }
  .details-box li:before {
    position: absolute;
    left: -9vw;
    top: 3vw;
  }
  .text{
    position: relative;
    left: 0;
    top: 15px !important;
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .text{
        top: 31vw;
  }
}
@media only screen and (max-width: 575px) {
  .text h1{
        font-size: 24px;
  }
  .register_icon {
        right: 17vw;
  }
  .find_icon{
        left: 38vw;
  }
  .details-image{
        width: auto;
  }
  .support_image{
        margin-left: 0;
  }
  .support_icon{
        right: 33vw;
  }
  .grow_icon {
    left: 28vw;
    top: 15vw;
}
  
}

/* Blog Styles */
#blog  .content_box h3{
    text-align: left;
    text-transform: uppercase;
    /*font-size: 40px;*/
    letter-spacing: 1px;
}
#blog .para{
  transform:initial;
  padding:0px;
}
#blog .content_box{
      width: 80%;
}
#blog .content_box p{
          font-size: 22px;
    letter-spacing: 1px;
}

.timeline {
  list-style: none;
  padding: 20px 0 0px;
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}
.timeline:before {
  top: 20px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #c6c6c6;
  left: 50%;
  height: 100vh;
  margin-left: -1.5px;
}
.timeline > li {
  margin-bottom: 20px;
  position: relative;
  width: 50%;
  float: left;
  clear: left;
}
.bl_detail{
  position: absolute;
  right: 0;
  text-align: center;
  bottom: -50px;
  transform: rotate(180deg);
}
.bl_detail_pro{
  transform: rotate(0deg);
      bottom: -19px;
}
.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li > .timeline-panel {
  width: 95%;
  float: left;
  border: 1px solid #d4d4d4;
  /*border-radius: 2px;*/
      margin-bottom: 50px;

  /*padding: 20px;*/
  position: relative;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}
.blog_start{
  float:left;
  width: 100%;
}
.emptyPost{
  text-align: center;
  padding: 100px 0px;
}
.timeline-inverted .timeline-panel{
  margin-bottom:20px !important;
}

.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #ccc;
  border-right: 0 solid #ccc;
  border-bottom: 15px solid transparent;
  content: " ";
}
.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}
.timeline > li > .timeline-badge {
  color: #fff;
  width: 24px;
  height: 24px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  right: -12px;
  /*background-color: #999999;*/
  z-index: 100;
  /*
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  */
}
.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.timeline-badge > a {
  color: #3f57f4!important;
    font-size: 11px;

}
.timeline-badge a:hover {
  color: #000 !important;
}
.timeline-title {
  margin-top: 0;
  color: inherit;
}
.timeline-body > p,
.timeline-body > ul {
    padding:20px;
    margin-bottom: 0;
}
.timeline-body > p + p {
  margin-top: 5px;
}

.timeline-footer{
    padding:20px;
    background-color:#f4f4f4;
}
.timeline-footer > a{
    cursor: pointer;
    text-decoration: none;
}
.tooltip{

    position:absolute;
    z-index:1020;
    display:block;
    visibility:visible;
    padding:5px;
    font-size:11px;
    opacity:0;
    filter:alpha(opacity=0);
    
}

.tooltip.top{
    margin-top:-2px;
}
.tooltip.right{
    margin-left:2px;
}
.tooltip.bottom{
    margin-top:2px;
}
.tooltip.left{
    margin-left:-2px;
}
.tooltip.top .tooltip-arrow{
    bottom:0;
    left:0;
    margin-left:0;
    border-left:0 solid transparent;
    border-right:5px solid transparent;
    border-top:0 solid #000;
}
.tooltip.left .tooltip-arrow{
    bottom:0;
    left:0;
    margin-left:0;
    border-left:0 solid transparent;
    border-right:5px solid transparent;
    border-top:0 solid #000;
}
.tooltip.bottom .tooltip-arrow{
    bottom:0;
    left:0;
    margin-left:0;
    border-left:0 solid transparent;
    border-right:5px solid transparent;
    border-top:0 solid #000;
}
.tooltip.right .tooltip-arrow{
    bottom:0;
    left:0;
    margin-left:0;
    border-left:0 solid transparent;
    border-right:5px solid transparent;
    border-top:0 solid #000;
}
.tooltip-inner{
    width:200px;
    padding:3px 8px;
    color:#fff;
    text-align:center;
    text-decoration:none;
    background-color:#313131;
    -webkit-border-radius:0px;
    -moz-border-radius:0px;
    border-radius:0px;
}
.tooltip-arrow{
    position:absolute;
    width:0;
    height:0;
}
.timeline > li.timeline-inverted{
  float: right; 
  clear: right;
  margin-bottom: 30px;
}
ul.blogcont-list {
  margin-left: 15px;
}
ul.blogcont-list li {
  list-style: disc;
  font-size: 15px;
  color: #333;
}
.timeline > li:nth-child(even){
  margin-top: 60px;
}
.timeline > li.timeline-inverted > .timeline-badge{
  left: -12px;
}

@media (max-width: 767px) {
    ul.timeline:before {
        left: 40px;
    }

    ul.timeline > li {
      margin-bottom: 20px;
      position: relative;
      width:100%;
      float: left;
      clear: left;
    }
    ul.timeline > li > .timeline-panel {
        width: calc(100% - 90px);
        width: -moz-calc(100% - 90px);
        width: -webkit-calc(100% - 90px);
    }

    ul.timeline > li > .timeline-badge {
        left: 28px;
        margin-left: 0;
        top: 16px;
    }

    ul.timeline > li > .timeline-panel {
        float: right;
    }

        ul.timeline > li > .timeline-panel:before {
            border-left-width: 0;
            border-right-width: 15px;
            left: -15px;
            right: auto;
        }

        ul.timeline > li > .timeline-panel:after {
            border-left-width: 0;
            border-right-width: 14px;
            left: -14px;
            right: auto;
        }
    
.timeline > li.timeline-inverted{
  float: left; 
  clear: left;
  margin-top: 30px;
  margin-bottom: 30px;
}

.timeline > li.timeline-inverted > .timeline-badge{
  left: 28px;
}


}
#blog .bl-container{
      max-width: 962px;
}
.timeline-body.row {
    padding: 15px 30px;
}
.blog_contents{
  font-size:14px;
}
.socialmedias{
      font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
}
.socialmedias span:nth-child(1){
      background: #3b5999;
    color: #fff;
  font-size:12px;
    width: 20px;
    display: inline-block;
    border-radius: 100%;
    height: 20px;
    text-align: center;
    padding-top: 1px;
}
.socialmedias span:nth-child(2){
      background: #1574a4;
    color: #fff;
    width: 20px;
  font-size:12px;
    display: inline-block;
    border-radius: 100%;
    height: 20px;
    text-align: center;
    padding-top: 1px;
}
.socialmedias span:nth-child(3){
      background: #58adee;
    color: #fff;
  font-size:12px;
    width: 20px;
    display: inline-block;
    border-radius: 100%;
    height: 20px;
    text-align: center;
    padding-top: 1px;
}
.blog_contents p:nth-child(3){
  margin-top:5px;
}
.blog_contents h5{
    margin-bottom: 20px;
    font-family: circular-bold;
    font-size: 18px;
}
.blog_date p:nth-child(1){
  font-size:32px;
    line-height: 30px;  
      font-family: circular-bold;
      margin-bottom: 5px;
}
.blog_date p:nth-child(2){
  font-size:26px;
    font-family: circular-bold;
text-transform:capitalize;
 
}
 
 .react-spinner-material{
  width: 20px !important;
  height: 20px !important;
  border-color: rgb(51, 51, 51);
  border-width: 5px;
  float: right;
  margin-left: 14px;

 }
p.socialmedias {
    margin: 0px;
    font-size: 13px;
}
.bl_title{
      margin: 30px 0px;
      text-align: center;
    text-transform: capitalize;
    font-family: circular-bold;
    margin-bottom: 0px;
}
#blog .form-control-borderless {
    border: none;
}
.card-body.row.no-gutters.align-items-center {
    padding: 0;
}
.col-auto{
        position: absolute;
    right: 0;
    top: 0px;
    background: #01b170;
    color: #fff !important;
    padding: 0px 25px !important;
    border-radius: 4px;
    line-height: 56px;
}
.search_blog {
    margin-top: 50px;
}
input[type="search"]::-webkit-input-placeholder {
  color: #c9c9c9;
    font-family: circular-regular;
  border-color:red;
  font-size:16px;
}
input.form-control.form-control-lg {
    height: 55px;
}
.keyword_blog li{
    float: left;
    background: #f7f7f7;
    padding: 10px 30px;
    margin-left: 15px;
    border-radius: 40px;
  
}
.keyword_blog li a{
      color: #000;
}
.keyword_blog ul{
      display: inline-block;
    margin: 0;
      padding: 0px;
}
.keyword_blog {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
      font-family: circular-regular;
    margin-top: 30px;
    margin-bottom: 30px;
}
.form-control{
  background:initial;
  height: 40px;
}
.offer-footer p{
    font-size: 27px;
}
button.css-48ayfv, button.css-1r4vtzz {
  width: 100%;
}
.inputmultiselect-dropdown .multiselect-container input {
  position: absolute;
  left: 15px;
  top: 5px;
}
.multiselect-container .search-wrapper {
  min-height: 38px;
}
.inputmultiselect-dropdown .multiselect-container .optionContainer li {
  padding-left: 50px;
  position: relative;
}

.offerspan{
  font-size: 12px;
    padding-left: 0px !important;
    text-transform: capitalize;
    color: #fff;
  }
.col-auto i{
     color: #fff !important;
         font-size: 24px;
}
#blog .form-control-borderless:hover, .form-control-borderless:active, .form-control-borderless:focus {
    border: none;
    outline: none;
    box-shadow: none;
}
.pagination{
      float: left;
    display: flex;
    justify-content: center;
    width: 100%;
      margin: 50px 0px;
}
.pagination li{
      border: 1px solid;
    border-radius: 100%;
    width: 30px;
    display: inline-block;
    height: 30px;
    text-align: center;
    background: #293e69;
    color: #fff;
    line-height: 28px;
    font-size: 14px;
    margin-left: 15px;
}
.pagination li.active{
  background:#01b170;
}
.pagination li a{
  color:#fff;
  font-family: circular-regular;
}
@media only screen and (max-width: 993px) {
  .timeline-body.row{
        padding: 15px 15px;
  }
  .cl_set{
        height: 450px;
  }
  .cl_set img{
        height: 100%;
  }
  .slider_out{
        width: 680px;
  }
}
@media only screen and (max-width: 767px) {
  .timeline:before  {
    content:initial !important;
  }
  
  .slider_out{
    width:90%;
  }
  .keyword_blog{
    display:block;
  }
  .keyword_blog span{
        display: block;
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 20px;
  }
  .keyword_blog ul {
        width: 100%;
    display: block;
    float: left;
  }
  .keyword_blog li a{
        font-size: 13px;
  }
  .blog_start{
    width:100%;
    margin-bottom: 25px;
  }
  ul.timeline > li > .timeline-panel , .timeline > li.timeline-inverted > .timeline-panel{
    float: initial;
    max-width: 442px;
    margin: 0 auto;
  }
  
  
}

.letstk_srv {
  font-family: circular-bold;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #000;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 600px) {
  .latest_blog h1 {
    margin: 20px 0px !important;
  }
  section.online_course {
    padding-top: 15px;
    padding-bottom: 10px;
  }
  .online_content .br_set {
    margin-bottom: 0px;
  }
  .br_set li {
    margin-bottom: 20px;
  }
  .react-responsive-modal-modal {
    padding: 10px !important;
    width: 95% !important;
    margin: 0px !important;
  }
  .details-box {
    text-align: center;
  }
  .details-box ul {
    text-align: left;
  }
  section.service_desc {
    top: -30px !important;
  }
  .letstk_srv {
    top: 0px !important;    
  }
  .popup-w .pop-content .subs-form-card .subs-form form .form-field .errorMsg {
    color: rgb(164 36 61) !important;
  }
  .services li{
        max-width: 100%;
  }
  .mbl_view_out{
   
    /* float: left; */
    /* height: auto; */
    align-items: center;
      
  }
  .mbl_view{
        float: left;
    width: 100%;
  }
   
  .services p{
    font-size:14px !important;
  }
  .service_list img{
        margin: 0 auto;
  }
  .keyword_blog li{
        margin-bottom: 10px;
  }
  section.slider_set {
    min-height: auto !important;
  }
  .slider_in{
    min-height: auto;
    background: #0d326a;
    padding: 115px 0px;
    padding-top: 20px;
  }
  .cl_set{
    display:none;
  }
  .testimonial_set {
     padding: 30px 50px !important;
    }
  .cl_title h1{
        font-size: 24px;
          padding: 15px 0px;
  }
  .cl_title p {
        margin-bottom: 20px;
      padding:0px;
  }
  
  .online_course h1{
        margin-bottom: 30px;
    text-align: center;
    font-size: 24px;
    margin-top: 30px;
  }
  .v_set.video3{
        left: -27px;
  }
  .slider_out{
    position:relative;
  }
  
}
@media only screen and (max-width: 481px) {
  .testimonial_slider{
        width: 100%;
  }
  .f_details h4 span{
    display: inline-block;
  }
  ul.timeline > li > .timeline-panel , .timeline > li.timeline-inverted > .timeline-panel{
    max-width: 100%;
    width:100%;
  }
  .keyword_blog li{
           float: initial;
    background: #f7f7f7;
    padding: 10px 30px;
    margin-left: 15px;
    border-radius: 40px;
    max-width: 62%;
    text-align: center;
    margin: 0 auto 10px;
  }
  #blog .content_box h3{
        font-size: 28px;
          padding-top: 30px;
  }
  #blog .content_box p{
        font-size: 14px;
    letter-spacing: 0;
  }
}

/*Home and Service Responsive */
@media only screen and (max-width: 1360px) {
  .services ul{
        margin: 0px 0px;
  }
  .services .row{
    margin:0px;
  }
  .mem_set{
        margin: 0px 0px 50px;
  }
  .hv_img{
        max-width: 20%;
  }
}
@media only screen and (max-width: 1201px) {
   
  .leads_call{
        width: 95%;
  }
  .hole_service{
        overflow:hidden;
  }
  section.service_desc{
        margin-bottom: 0px;
  }
  a.aded{
        font-size: 16px;
  }
  .back_video{
        height: auto;
  }
  .services p{
        font-size: 14px;
  }
  .service_list img{
    width:60%;
  }
  .slider_out{
        bottom: 0px;
  }
  .testimonial_set{
        padding: 44px 30px;
  }
  section.slider_set{
        min-height: 700px;
  }
  .se_set{
        padding-top: 20px !important;
  }
}
@media only screen and (max-width: 993px) {
  .seo_down{
        bottom: 0;
  }
  .set_dots .slick-dots{
        left: 0px;
  }
  .circle_out{
    left: -367px;
    top: -23px;
    width: 676px;
  }
  svg.arrow-end{
        top: 178px;
        right: 42px;
  }
  .se_set p {
    line-height: 26px;
    margin-top: 15px;
  }
  .f_details {
        font-size: 15px;
  }
  .banner h1{
    width:auto;
        font-size: 32px;
  }
  .banner h3{
    width:auto;
      font-size: 21px;
    margin-top: 16px;
  }
  .services p {
    font-size: 1.3vw;
}
.v_set.video2 , .v_set.video1 , .v_set.video3{
      width: 250px;
}
 .v_set.video1 , .v_set.video3{
       width: 300px;

 }
  .v_set.video1{
     bottom: 100px;
  }
  .c_set{
    left:0px;
    bottom: 0px;
    width: 100%;
  }
  .blog_content{
        width: 90%;
  }
  ul.leads_call h2{
        font-size: 16px;
  }
  .service_desc h1{
        font-size: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .hanges_text{
        height: 200px;
          top: -75px;
        width: 300px;
  }
  .tr_seo{
    width: 100%;
  }
  .circle_out , .slider_content{
    display:none;
  }
  .se_set{
      width: 95%;
  }
  .banner_title{
        padding: 10px 0px;
  }
  .leads_call{
        margin: 10px 0px;
  }
  .service_desc h1{
    letter-spacing: 0px;
    margin-top: 50px;
  }
  section.service_client{
        margin: 50px 0px 30px;
  }
  .service_client ul{
    padding:0px;
  }
  
}
@media only screen and (max-width: 481px) {
  .hanges_text{
      width: 225px; 
  }
  .seo_activity{
        max-width: 100%;
  }
  .seo_down {
    height: 140px;
  }
  .seo_content{
    margin-top:60px;
  }
  .srvmblclt {
    display: none;
  }
  .tr_seo div:nth-child(1), .tr_seo div:nth-child(2){
    margin-bottom: 30px;
  }
  .leads_call{
      width: 100%;
  }
  ul.leads_call h2 {
    font-size: 11px;
  }
  .we_work h1{
    font-size:24px;
  }
  .cl_title h1{
        font-size: 24px;
  }
  .v_set {
    position: relative;
    left: 0;
        top: auto !important;
    bottom: 0;
        margin: 0 auto;
        width: auto !important;
  }
  .br_set li{
        width: 50%;
  }
  .slider_out{
        width: 90%;
  }
  .slider-left-content h5, p, a{
        font-size: 14px;
  }
  .testimonial_set {
    padding: 15px 30px;
  }
  .slider_set h4{
        font-size: 16px;
  }
  section.slider_set {
    min-height: 735px;
  }
  .online_course h1{
    padding: 0px 0 15px !important;
    text-align: center;
    font-size: 24px;
  }
  .online_course .row{
        margin-top: 50px;
  }
.online_content h2 , .membership_program .t_title{
      margin-bottom: 15px;
    font-size: 24px;
}
.online_content {
  margin-top: -50px;
}
.hv_img {
    max-width: 100%;
    justify-content: center;
}
.hv_content {
    width: 88%;
}
  .v_set.video1 {
    bottom: 0px;
    left: 0 !important;
    right: auto !important;
  }
}


/*Home and Service Responsive */
.blog_banner{
  /*background-image:url('./images/blogdetail_banner.jpg');*/
  height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.top_section{
    display: flex;
    margin-bottom: 30px;
    margin-top: 30px;
}
.rlpost_list h5{
  text-align:center;
  margin-top: 41px;
}
.rlpost_list{
  text-align:center;
}
.blog_para b{
  font-size:36px;
}
.blog_para p{
      font-family: circular-regular;
        line-height: 36px;
}
.blog_para h5{
      font-family: circular-bold;
    margin-bottom:20px;
}
.rl_posts{
  height: 300px;
  position:relative;
}
.rl_posts div{
    font-size: 13px;
    text-align: center;
    color: #000;
    font-family: circular-bold;
}
.rl_posts div p {
  margin: 0;
  word-wrap: break-word;
  text-align: left;
  margin: 0 40px;
  margin-bottom: 16px;
}
.rl_posts div p:hover {
  text-decoration: underline;
}
.top_section .bl_date{
  width:10%;
}
.bl_date strong{
       font-size: 32px;
    font-family: circular-regular;
    text-align: center;
    display: block; 
}
.blog_dates h3{
      font-family: circular-bold;
        line-height: 40px;
}
.blog_dates{
  padding-left: 30px;
}
.blog_dates p{
      font-size: 15px;
    color: #333;
    margin-top: 10px;
}
.blog_dates span {
  display: inline-block;
}
.blog_dates span ul {
  display: flex;
  align-items: self-start;
  padding-left: 0;
}
.blog_dates span ul li{
    margin-left: 14px;
}
.bl_date p{
    font-size: 24px;
    text-align: center;
    font-family: circular-bold;
 
    letter-spacing: 1px;
}
.social_fb {
  background-color: #3b5995;
}
.social_linkedin {
  background-color: #007ab9;
}
.social_twitter {
  background-color: #56aeed;
}
.social_links {
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 100%;
  width: 30px;
  text-align: center;
  height: 30px;
  line-height: 2;
}
.end_para {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  margin-top: 4rem;
  padding-top: 2rem;
}
.end_para a {
  text-decoration: none;
  color: #3b5995;
}
@media only screen and (max-width: 481px) {
  .top_section {
    flex-flow: column;
  }
  .top_section .bl_date {
    width: 100%;
  }
  .blog_dates h3 {
    font-size: 18px;
  }
  .blog_dates {
    padding-left: 0; 
  /*text-align: center;*/
  }
  
  .rl_posts {
    background-size: cover;
  }
  .rl_posts p { 
    transform: translateY(8px);
  }
  .end_para { 
    text-align: center;
  }
}


/* signup starts */
.signup-banner {
  background-image: url('./images/signup-bg-img.png');
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  height: 590px;
  transform: translateY(50px);
  font-size: 14px;
}
.btn {
  width: 100%;
}
.form-control {
  border: 1px solid #e8e8e8;
}
.signup-container {
  max-width: 1320px;
}
.signup-container .row {
  padding-top: 20px;
}
.signup-form {
  padding-right: 0px;
}
.signup-form span {
  padding-right: 70px;
  padding-left: 10px;
      color: #6c757d;
}
.signup-eye {
  padding-right: 66px ! important;
  padding-left: 10px;
      color: #6c757d;
}
.btn-outline-secondary {
  background-color: transparent !important;
}
.alg_set {
  float: left;
  width: 100%;
}
.image-col {
  text-align: right;
}
.image-col img {
  width: 350px;
}
.line {
  display: flex;
  align-items: center;
}
.line::before {
  content: '';
  flex: 1;
  margin-right: 1rem;
  height: 1px;
  background-color: #6c757d;
}
.line::after {
  content: '';
  flex: 1;
  margin-left: 1rem;
  height: 1px;
  background-color: #6c757d;
}
.btn {
  background-color: #202044;
}
.links a {
  color: #4081a7;
}
@media only screen and (max-width: 1024px) {
  .signup-banner {
    background-position-x: 48vw; 
    height: 610px;
  }
  .signup-container .row {
    padding-top: 0;
  }
  .signup-form {
    padding: 0 4vw 0 3vw;
  }
  .image-col img {
    width: 35vw;
  }
  .image-col {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .signup-banner {
    background-image: none; 
    height: 730px;
    transform: translateY(20px);
  }
  .image-col {
   padding-top: 45px;
  }
  .image-col img {
    width: 250px;
  }
  .signup-form span {
    padding-right: 12vw;
  }
}
.sub_service li ul li:last-child a, .sub_membership li:last-child a{
  border:0px !important;
}
.whyjoin .mb-orow {
  margin-bottom: 0px !important;
}
.whyjoin .mb-orow .whycct-mbl0 ul {
  margin-bottom: 0px;
}
.whyjoin .mb-orow .whycct-mbl0 ul li:last-child {
  margin-bottom: 0px;
}
/* signup ends */

/* career */
  .whyjoin {
    margin-top: 20px;
  }
  .whyjoin h1 {
    margin-top: 0px !important;
  }
  .join_ez {
    padding-bottom: 20px;
  }
 .whyjoin h1  , .join_ez h1 , .social h1{
  font-family: circular-bold,sans-serif;
  color: #000;
  text-transform: uppercase;
  font-size: 36px;
  text-align: center;
  margin: 50px 0px 30px;
 }
 .job_setail{
   display: none;
   -webkit-box-shadow:-1px 0px 5px 0px rgba(0, 0, 0, 0.15);
   -moz-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.15);
   box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.15);
   padding: 30px 45px;
   margin-top: 30px;
 }

 .social ul{
  margin: 50px auto 50px auto;
  width: 20%;
  display: flex;
  justify-content: space-between;
  padding: 0;
 }
 
 section.social {
  margin: 70px 0px;
}
 .social h1{
   text-transform: capitalize;
   margin-bottom: 20px;
 }
 .social h3{
  font-family: circular-bold,sans-serif;
  color: #000;
  text-align: center;
 }
 .applynow span{
   color:#0064b0;
 }
 .applynow button.btn.btn-success{
  background: #0064b0;
  height: 45px;
  font-size: 16px;
  margin: 10px 0px;
  width: 150px;
 }
 .applynow{
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ddd;
  padding-top: 30px;
  margin-top: 50px;
 }
 .applynow h1{
  font-size: 22px;
  text-transform: capitalize;
  text-align: left;
  line-height: 36px;
    margin: 0;
  font-family: circular-bold,sans-serif;
 }
 .experience {
  margin-top: 30px;
}
 .experience h3{
   margin-bottom: 30px;
 }
 .join_ez ul{
  padding-left: 0;
  width: 80%;
  margin: 0 auto;
 }
 .join_ez li:last-child{
   border: 0px;
 }

 .join_ez li{
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
 }
 .jb_title{
  display: flex;
  justify-content: space-between;
 }
 .join_ez li h3{
  font-family: circular-bold,sans-serif;
  color:#000;
  letter-spacing: 1px;
 }
 section.whyjoin {
  background: #f1f1f1;
  padding: 50px 0px;
  text-align: center;
}
.whyjoin .row{
  margin-bottom: 30px;
}
.whyjoin li{
  margin-bottom: 30px;
}
.whyjoin ul h3{
  font-family: circular-bold,sans-serif;
  color: #000;
}
.whyjoin ul p{
  line-height: 30px;
  font-family: circular-bold,sans-serif;
  color: #000;
  width: 80%;
  margin: 0 auto;
}
/* career */

/*19-12-2020*/
.errspan {
        float: right;
        margin-right: -40px;
        margin-top: -28px;
        position: relative;
        z-index: 2;
        cursor: pointer
       
    }
.signup-form a{
  color :#2e5f7f ! important; 
   
}
.proposal{
   font-size: 12px; 
   border: 1px solid #2e5f7f;
   color :#2e5f7f ! important; 
   padding-bottom: 5px;
    padding-top: 5px;
    border-radius: 2px;
    padding-left: 15px;
    margin-left: 13px;
    padding-right: 10px ! important;

}
 .checkspan {
        
        /*margin-top: -28px;*/
       float: right;
      margin-right: 232px;
      margin-top: 3px;
      position: relative;
      z-index: 2;
      color: #0b2d4f ! important;
    }
.al_setpropsal{
  display: flex;
  justify-content: space-between;
}
.login-top{
  font-size: 12px;
  color :#1f87e3 ! important;
}
.google{
  padding: 10px 130px 10px 130px;
}
.check{
  margin-left: -190px ! important;
}
.bluebtn{
  background-color: #0b2d4f ! important;
    border-color: #0b2d4f ! important;
}
.login-heading{
  text-align: center;
  margin-top: 15px;
  margin-bottom: 25px;
  font-size: 19px;
  color: #000;
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
  /* font-weight: 700; */
  line-height: 30px;
}
input[type=checkbox] {
         position: relative;
         cursor: pointer;
    }
    input[type=checkbox]:before {
         content: "";
         display: block;
         position: absolute;
         width: 25px;
        height: 22px;
        top: -3px;
        left: -2px;
        border: 2px solid #d8dbe0;
        border-radius: 6px;
         background-color: white;
}
    input[type=checkbox]:checked:after {
         content: "";
         display: block;
         width: 7px;
        height: 12px;
         border: solid #1f87e3;
         border-width: 0 2px 2px 0;
         -webkit-transform: rotate(45deg);
         -ms-transform: rotate(45deg);
         transform: rotate(45deg);
         position: absolute;
         top: 1px;
          left: 7px;
}
/*22-12-2020*/
.cn{
  letter-spacing: 1px;
    font-family: circular-regular,"HelveticaNeue","Arial",sans-serif ! important;
    font-size: 35px ! important;
}
.cntop{
  margin-left: -90px;
    /*margin-top: -250px;*/
}
.para button.btn.btn-success {
    width: 225px;
    background-color: #01b170;
    font-size: 20px;
    border-radius: 5px;
    height: 50px;
    position: relative;

}
.para button.btn.btn-success img {
  width: 17px;
}
  .param button.btn.btn-success {
    border-radius: 1px;

  }
  .cnform{
  background-color: #EBEDEF ! important;
    width: 100%;
    font-size: 19px;
    color: #000;
  }

  /* .cnform .css-1rhbuit-multiValue {
    opacity: 0;
  } */

  .cnleft{
    color: #293F68;
    font-size: 40px;
    /* margin-top: 50px; */
    margin-bottom: 50px;
  }

.content .tooltiptext {
  visibility: hidden;
  width: 450px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  margin-top: -75px;
}

.contmap_info {
  width: 300px;
  margin: 70px auto 50px auto;
  position: relative;
}

.loct-info1 {
  position: absolute;
  left: 0;
  top: 30px;
  transform: translate(50%, 0);
}

.loct-info2 {
  position: absolute;
  left: 52%;
  top: 30px;
  transform: translate(-50%, 0);
}

.loct-info3 {
  position: absolute;
  right: -4%;
  top: 30px;
}

.content:hover .tooltiptext {
  visibility: visible;
}

/*23-10-2020*/
.terms{
    background-color: #fff;
  }

  p.terms_list {
    background: #edf4fb;
    font-size: 16px;
    padding: 10px;
}
.termsul {
    background: #f5f5f5;
    font-size: 16px;
    padding: 10px;
}
.toolmaphover {
  margin-left: 200px;
}
.leftspan {

   display: inline-block;
    width: 0;
    height: 17px;
    border-left: 2px solid rgba(0, 0, 0, 0.125);
    /* border-right: 1px solid #ccc; */
    margin-right: 10px;
  }
.borderLeft {
border-bottom: none ! important;
    /*border-left: 2px solid rgba(0, 0, 0, 0.125) ! important;*/
    /*margin-left: 27px;*/
}
ul.b {
 
    list-style-type: disc !important;
    padding-left:10px !important;
}
.accordion__button:before{
      display: inline-block;
    /*content: '';*/
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
}
.accordion__button
{
  font-family: circular-bold !important;
}
.form-cusinl-b2:hover button {
  background: #26994d;
}
.sm
{
  font-size:9px ! important;
    margin-right: 10px;
  }
.acc_msg
  {
     background: #fdfdfd;
     display: flex;
     -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
  }
  .acc_p
  {
    margin-top: 10px;
    margin-left: 10px;
    /* margin-right: 20px; */
    padding-left: 10px;
    border-left: 3px solid green;
    height: auto;
    font-size: 14px !important;
  }

  .faq_banner {
    height: 365px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
}

.column {
  float: left;
  width: 50%;
  padding: 50px;
  height: 300px; /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row:after {
  /* content: ""; */
  display: table;
  clear: both;
}
@media only screen and (max-width: 1200px) {
    .para {
      padding-left: 0px;
  }
  .contact_banner .banner_content{
    background-position: right;
  }
  .param button.btn.btn-success{
    width: auto;
  }
  .para{
    transform: inherit;
  }
  .para p , .para h5{
    font-size: 18px !important;
  }
  .cnform label{
    font-size: 14px;
  }
  .para button.btn.btn-success{
    width: auto;
    font-size: 18px;
  }
  .h_we_work .contact_banner{
    background-size: cover;
  }
  .contact_banner{
    background-size: 60% 100%;
  }
}
 /* Safty page style */

.safty_banner {
  height: 400px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.wt-belve {
  text-align: center;
  padding-top: 70px;
  padding-bottom: 70px;
  background: #fff;
}
.inter-asses-right h3 {
  margin-bottom: 20px;
  font-family: circular-bold;
}
.inter-asses-right p {
  font-size: 16px;
}
.net-prot-left h3 {
  margin-bottom: 20px;
  font-family: circular-bold;
}
.net-prot-left p {
  font-size: 16px;
}
.wt-belve h1 {
  text-transform: uppercase;
  padding-bottom: 20px;
  font-family: circular-bold;
}
.feature-tabs
{
font-family: circular-bold;
}
.wt-belve p {
  font-size: 16px;
}
.feature-tabs ul {
  display:table;
  width: 100%;
}
.feature-tabs li {
  text-align: center;
}
.net-prot {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #e9e9e9;
}
.net-prot-right img {
  width: 32%;
  display: block;
  margin: auto;
}
.inter-asses {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #fff;
}
.inter-asses-left img {
  width: 32%;
  display: block;
  margin: auto;
}
.feature-tabs ul  {
  padding-left: 0;
  margin-bottom: 0;
}
.feature-tabs ul li {
  padding-top: 35px;
  padding-bottom: 35px;
  border: 1px solid #888;
  width: 33.33%;
  display: inline-block;
  cursor: pointer;
}
.feature-tabs ul li span {
  display: block;
}
.feature-tabs ul li h2 {
  color: #333;
}
.feature-tabs ul li.active {
  background: #4282D9;
}
.feature-tabs ul li.active h2 {
  color: #fff;
}
.feature-tabs ul li.active span {
  color: #fff;
}

/* grv */
h2.sb_title {
  margin: 20px 0px;
  font-family: circular-bold;
  color: #6d6d6d;
  font-size: 18px;
  line-height: 36px;
}
.custom-control.custom-radio.custom-control-inline.col-lg-12 {
  margin-bottom: 25px;
}
.gr_form h1{
  font-family: circular-bold;
  text-transform: uppercase;
  font-size: 24px;
  color: #000;
  margin: 80px 0px 50px 0px;
}
.gr_form label{
  font-family: circular-regular,"HelveticaNeue","Arial",sans-serif;
  font-size: 16px;
  color: #6d6d6d;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after{
  background-image: inherit;
  background-color: #01b170;
  border-radius: 100%;
}
.sum_but button{
  font-family: circular-bold,sans-serif;
  max-width: 200px;
  padding: 10px;
  border-radius: 35px;
  font-size: 22px;
}

.react-responsive-modal-modal{
  width:80%;
  text-align: center !important;
  margin-top: 0px !important;
  /*padding: 0px !important;*/

  /*padding: 0px !important;*/
  /*min-height: 400px;*/
}
.react-responsive-modal-modal h1{
  /*text-align: center;*/
  color: #000000e0;
  font-family: circular-bold;
  font-size: 20px;
  line-height: 33px;
  margin: 10px 0px 22px;
}
.react-responsive-modal-modal h3{
  text-align: center;
    color: #321fdb;
    font-family: circular-bold;
    font-size: 21px;
    line-height: 46px;
    margin: 10px 0px 22px;
}
.job_setail.active{
  display: block;
}
.react-responsive-modal-modal .custom-file-label{
height: 41px;
line-height: 1.1;
border-color: #757272;
font-size: 20px;
}
p.succes {
  text-align: center;
  margin: 30px 0px 0px;
  color: green;
  font-size: 18px;
}
p.succes.error{
  color: red;
}
p.succes.suc{
  color: green;
}

.custom-file-input::placeholder{
  color: #000;
  font-size: 16px;
  font-family: circular-regular;
}
.react-responsive-modal-modal .form-control::-webkit-input-placeholder { /* Edge */
  /*color: #000;*/
  /*font-size: 16px;*/
  font-family: circular-regular;
}

.react-responsive-modal-modal .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  /*color: #000;*/
  /*font-size: 16px;*/
  font-family: circular-regular;
}

.react-responsive-modal-modal .form-control::placeholder {
  /*color: #000;*/
  /*font-size: 16px;*/
  font-family: circular-regular;
}
.react-responsive-modal-modal .form-control{
  height: 60px;
  /*color: #000;*/
  border-radius: 5px;
  /*border-color: #bfb8b8;*/
  margin-bottom: 0px !important;
}
.extra_links ul{
  display: flex;
  justify-content: space-between;
}
.extra_links ul a {
  color: #fff;
}
.extra_links ul li{
  position: relative;
}
.extra_links ul li:last-child:after{
  border: 0px;
}
.extra_links ul li:after{
  content: '';
  border: 1px solid;
  position: absolute;
  height: 70%;
  right: -20px;
  top: 6px;
}
.careerPage .contact_banner{
  background-size: cover;
  height: 365px;
}
.flastcontmb h4 {
  margin-bottom: 40px;
}
.extra_links{
  padding-top: 5px;
  margin:0 auto;
}
.o_product{
  max-width: 90%;
}
.main_pr .slick-slide  > div{
  text-align: center;
}
.main_pr .slick-prev:before, .main_pr .slick-next:before{
  color: #d4d4d4;
  font-size: 56px;
}
.main_pr button.slick-arrow.slick-prev{
  left: -200px;
}
.main_pr button.slick-arrow.slick-next{
  right: -180px;
}
.gr_form button{
  background: #01b170;
  font-family: circular-bold;
  border-color: #01b170;
  padding: 10px;
  border-radius: 0px;
  font-size: 22px;
}
.captch{
  margin: 20px 0px;
}
/* grv */


.navbar-nav .nav-link {
  padding-left: 0px !important;
  padding-right: 15px !important;
}

/* about page css */
.about-contsec {
  background: #fff;
}
.about-contsec  .contact_banner{
  height: 600px;
  background-size: cover;
}
.abt-ban-cont {
  padding-left: 25px;
  border-left: 5px solid #F30505;
  left: auto;
  margin-left: 100px;
}
.abt-ban-cont h2 {
  font-size: 70px;
  font-family: circular-bold;
  text-transform: uppercase;
}
.abt-complex {
  position: relative;
  font-size: 50px;
  padding-left: 35px;
  font-family: circular-bold;
  color: #333;
  margin-bottom: 30px;
}
.about-contsec p{
  font-family: circular-regular;
  color: #000;
  font-size: 16px;
  line-height: 30px;
}
.abt-complex::before {
  position: absolute;
  content: '"';
  top: 0;
  left: 0;
}
.abt-complex-left {
  line-height: 24px;
}
.lnh-24 {
  line-height: 24px;
}
.achive-box {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-left: 2px solid #ccc;
}
.achive-box-1 {
  border-left: none !important;
}
.achive-box h2 {
  font-size: 40px;
}
.achive-box h2, .achive-box p {
  color: #fff;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.achive-box p {
  font-size: 25px;
  margin-top: 15px;
}
.achive-box-cont {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 50px;
}
.achive-container {
  padding-left: 75px !important;
  padding-right: 75px !important;
  margin-top: 50px;
  margin-bottom: 70px;
}
.solution {
  margin-top: 50px;
}
.value-box .row {
  margin-left: 0px;
  margin-right: 0px;
}
.sec-title {
  margin: 70px 0px 70px 0px;
  text-align: center;
  font-family: circular-bold,sans-serif;
  color: #333;
}
.latest_blog h1 {
  color: #333;
}
.vluebox-left {
  background: #1B2644;
  padding-left: 100px !important;
  padding-top: 170px;
  padding-bottom: 15px;
  padding-right: 20px !important;
  position: relative !important;
  margin-top: 100px;
}
.vluebox-left-inner p {
  color: #fff;
}
.val-contbox {
  text-align: center;
  max-width: 270px;
  margin: auto;
}
.val-contbox img {
  width: 60px;
}
.val-contbox h5 {
  margin-top: 15px;
  font-family: circular-bold;
    color: #000;
}
.val-contbox p {
  margin-top: 10px;
}
.vluebox-right {
  padding-top: 50px;
  padding-bottom: 50px;
}
.vluebox-left img {
  position: absolute;
  top: -120px;
  right: -80px;
}
.vluebox-left-inner p {
  position: relative;
}
.vluebox-left-inner p i {
  position: absolute;
  top: -25px;
  left: 0px;
  font-size: 18px;
}
.blog-in {
  position: relative;
}
.blog-content ul {
  position: absolute;
  right: 13px;
  top: 10px;
  width: 76px;
  padding: 0px;
  margin: 0px;
}
.blog-content ul li {
  display: inline-block;
  margin-left: 6px;
}
.blog-dates {
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 5px 10px;
  background: #01B170;
}
.blog-dates p {
  font-size: 15px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  color: #fff;
}
.blog-cont-txt {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0px 20px;
  color: #fff !important;
}
.blog-cont-txt a {
  color: #fff;
  font-family: circular-bold;
}
.br_more button{
  font-family: circular-bold;
  color: #000;
}
.blog-content ul li img {
  width: 19px;
  height: 19px;
}
.btn-blogmore {
  font-size: 20px !important;
  color: #333 !important;
  display: inline-block;
  width: auto;
  padding: 0.3rem 0.75rem !important;  
}
.latest_blog h1 {
  margin: 20px 0px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 481px) {
  .contact_banner{
    height: 60vw;
  }
  .abt-ban-cont h2 {
    font-size: 26px;
  }
  .abt-ban-cont {
    margin-left: 20px;
  }
  .abt-complex {
    font-size: 30px;
    padding-left: 25px;
  }
  .achive-box-cont {
    padding: 0px;
  }
  .achive-box {
    border-left: none;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .vluebox-left img {
    position: relative;
    top: 0;
    right: 0;
  }
  .vluebox-left {
    padding-left: 20px !important;
    padding-top: 30px;
    margin-top: 0px;
  }
  .blog-in {
    margin-bottom: 10px;
  }
}


/* Responsive */
@media only screen and (max-width: 1200px){
  .banner_counts ul li{
    width: 165px;
    height: 165px;
  }
  .banner_counts ul li:after{
    width: 190px;
    height: 190px;
  }
  .extra_links ul li:after{
    display: none;
  }
  .latest_blog .col-sm-6{
    margin-bottom: 30px;
  }
  .latest_blog .col-sm-6:nth-child(3){
    margin: 0 auto;
  }
}

@media only screen and (max-width: 768px){
  .mn_set{
    position: relative !important;
    bottom:0px;
  }
  .col-lg-8.extra_links{
    float: left;
    padding: 0;
  }
  .navbar-nav{
    padding: 15px !important;
    left: 0;
  }
  .extra_links ul li{
    float: left;
    width: 100%;
  }
  .extra_links ul{
    display: inline-block;
  }
  .extra_links ul li:after{
    display: none;
  }

  ul.sub_service, .sub_membership{
    width: 100%;
    height: auto;
    position: inherit;
    top: 0;
    left: 0;
  }
  ul.sub_membership.course_list:before, ul.sub_membership.tools_list:before, ul.sub_membership.members_list:before {
    left: 35px;
  }
  .login_out {
    min-width: 200px;
    /* max-width: 200px; */
    margin: 0 auto !important;
}
  .sub_service li{
    max-width: 100%;
    float: inherit;
  }
  .col-lg-12.copy_rights {
    float: left;
  }
  
}
@media only screen and (max-width: 481px){
  .mn_set{
    position: relative !important;
    bottom:0px;
  }
  
  .banner_counts ul li{
    margin-bottom: 40px !important;
  }
  section.partners {
    margin: 25px 0px 10px;
}
.whole_svg h3{
  font-size: 24px;
} 
.whole_svg{
  padding: 30px;
}
.whole_svg p{
  font-size: 14px;
  margin: 0;
}
.home_products h2{
  padding: 30px 0px;
  font-size: 26px;
}
section.home_products{
  padding-bottom: 30px;
}
.footer-info-contact .ex_icon{
  position: relative;
  left: 0;
  display: inline-block;
  margin-right: 10px;
}

.banner_counts ul li:nth-child(2) , .banner_counts ul li:nth-child(3){
    overflow:visible;
  }
  .banner_counts ul li:after{
    top:-13px;
    left:-13px;
  }
}

/* aso app result */
.asoappresult-outs {
  /*margin-left: 250px;*/
  padding-left: 50px;
  padding-right: 50px;
  background: #FCFCFC;
}
.asoappresult-outs h2 {
  color: #12315D;
}
.asoappr-logo {
  padding-top: 50px;
  padding-bottom: 50px;
}
.asoappr-logo img {
  padding-top: 20px;
  width: 125px;
}

.asoappr-head p {
  padding-top: 20px;
}
.aso-padt-20 {
  padding-top: 20px;
}
.asoappr-desc ul {
  padding-left: 0px;
}
.asoappr-scrns-row {
  padding-top: 20px;
}
.asoappr-scrns-row img {
  width: 100%;
}
.asoappr-upgr-btn p {
  text-align: center;
}
.asoappr-upgr-btn {
  text-align: center;
  margin-top: 50px;
}
.asoappr-upgr-btn button {
  padding: 10px 20px;
  background: #01B170;
  display: inline-block;
  color: #fff;
  width: 150px;
}
.asoappr-instls p {
  padding-top: 10px;
}
/* Aso  about your app */
.asoaboutapp-outs {
  margin-left: 250px;
  padding-left: 50px;
  padding-right: 50px;
  background: #FCFCFC;
}
.asoaboutapp-outs h2 {
  color: #12315D;
}
.asoeasy {
  padding-top: 50px;
  padding-bottom: 250px;
}
.asoeasy h2 {
  padding-bottom: 20px;
  color: #12315D;
}
.asoeasy p {
  padding-bottom: 20px;
}
.asoeasy form input {
  width: 300px;
  padding: 10px 20px;
  border-radius: 5px;
}
.asoeasy form button {
  margin-left: 20px;
  padding: 10px 30px;
  border-radius: 5px;
  background: #01B170;
  color: #fff;
  border: none;
}

/* ASO dashboard */
.asopackage {
  padding-top: 100px;
  padding-bottom: 100px;
}
.asopackage-box {
  text-align: center;
  background: #F9F9F9;
  box-shadow: 0px 0px 10px #ccc;
  padding: 30px 20px;
}
.asopackage-box h2{
  color: #4DA4E9;
}
.asopackage-box ul {
  padding-top: 30px;
  margin-left: 0px;
  padding-left: 0px;
}
.asopackage-box ul li i {
  padding-right: 8px;
}
.asopackage-box ul li {
  padding-bottom: 10px;
}
.asopackage-box button {
  background: #3BA9FF;
  color: #fff;
  display: inline-block !important;
  width: 130px;
}
/* .asopackage-col-2 {
  z-index: 1;
} */
.asopackage-box {
  transition: 0.5s;
}
.asopackage-box:hover {
  transform: scale(1.2);
  background: #fff;
  z-index: 1;
}
.aso-overview-row {
  padding-top: 20px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.aso-ovr-box {
  text-align: center;
}
.aso-ovr-box-1, .aso-ovr-box-3 {
  background: #C7E7FE;
}
.aso-ovr-box-2, .aso-ovr-box-4 {
  background: #fff;
}
.aso-col {
  padding-left: 0px !important;
  padding-right: 0px !important;  
}
.aso-ovr-box {
  padding-top: 40px;
  padding-bottom: 35px;
}
.aso-ovr-box p {
  margin-bottom: 5px;
  font-size: 16px;
}
.aso-overview {
  padding-top: 50px;
  padding-bottom: 50px;
}
.aso-perform ul {
  width: 80%;
  background: #fff;
  box-shadow: 0px 0px 10px #ccc;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 30px;
}
.aso-perform ul li {
  display: inline-block;
  width: 33.33%;
  text-align: center;
}
.aso-perform ul li p { 
  font-size: 16px;
  padding-top: 5px;
}
.aso-outs {
  background: #FCFCFC;
}
.aso-perform {
  padding-bottom: 50px;
}
.aso-perform ul li:nth-child(1) h2 {
  color: #0185E9;
}
.aso-perform ul li:nth-child(2) h2 {
  color: #CB487C;
}
.aso-perform ul li:nth-child(3) h2 {
  color: #FED501;
}
.aso-perform ul li:nth-child(2) {
  border-left: 1px solid #ccc;
}
.aso-perform ul li:nth-child(3) {
  border-left: 1px solid #ccc;
}
.aso-analysis-table {
  margin-top: 30px;
  margin-bottom: 50px;
  box-shadow: 0px 0px 10px #ccc;
}
.aso-analysis-table .table {
  margin-bottom: 0px;
}
.aso-analysis-table thead {
  background: #C7E7FE;
}
.ppc-analysis-table {
  margin-top: 30px;
  margin-bottom: 50px;
  box-shadow: 0px 0px 10px #ccc;
}
.ppc-analysis-table .table {
  margin-bottom: 0px;
  border:none;
}
.ppc-analysis-table thead {
  background: #ececec;
}
.ppc-analysis-table tbody {
  background: #fff;
}
.ppc-analysis-table tbody tr th {
  font-size: 11px;
  font-weight: bold;
}
.table-striped tbody tr:nth-of-type(odd) {
  background: #F5F8FF !important;
}
.aso-comparison-graph h5 {
  color: #1C2F4D;
}
.graph-dots img, .graph-dots p {
  display: inline-block;
}
.about-contsec .contact_banner {
  height: 365px;
}
.graph-dots img {
  width: 10px !important;
}
.graph-dots p {
  padding-left: 5px;
  margin-right: 20px;
}
.aso-comparison-graph {
  padding: 30px;
  box-shadow: 0px 0px 10px #ccc;
  margin-top: 30px;
}
.aso-comparison-graph-img {
  width: 100%;
}
.competitor-left-img > img {
  width: 65%;
  margin: auto;
  display: block;
}
.competitor-left-img {
  position: relative;
}
.competitor-left-cont {
  position: absolute;
  top: 55%;
  left: 52%;
  transform: translate(-50%,-50%);
}
.d-block {
  display: block;
}
.competitor-row {
  padding-top: 30px;
}
.aso-competitor {
  padding-top: 50px;
  padding-bottom: 50px;
}
.aso-analysis-table tbody td img {
  display: inline-block;
  width: 10px;
  margin-right: 5px;
}
.smo-record-box {
  padding-top: 30px;
}
.smo-records {
  padding-bottom: 50px;
}
.smo-post-table tbody td {
  align-items: center;
}
.smo-post-table tbody td img {
  width: 100% !important;
  padding-right: 10px;
  padding-left: 10px;
}
.smo-outs {
  background: #FCFCFC;
}
.collapse .nav-link {
  padding-top: 12px !important;
}
.smo-analysis-2 {
  width: 80%;
}
.aso-analysis tbody td {
  align-items: center;
}
.dateselector {
  float: right;
}
.dateselector .btn-default {
  border: 1px solid #ccc;
  width: 100px;
  padding-left: 0px;
}
/* .aso-outs, .work_outs, .agreement, .posible, .smoaboutbus-out, .smopossible-out, .other_services, .seoserv-out, .asoab_panel {
  margin-left: 250px;
  padding-left: 50px;
  padding-right: 50px;
} */
.aso-perform ul  {
  padding-left: 0px;
}
.weektext{
    font-size: 16px;
    padding-top: 31px;
    padding-bottom: 10px;
    text-transform: uppercase;
    color: #293e69;
    font-family: 'circular-bold';
  }
  .weeknumber{
  font-size: 35px;
    /* font-weight: 400; */
    font-family: 'circular-bold';
    padding-bottom: 25px;
  }
.col-lg-3.weekly_cls{
  box-shadow: 1px -1px 10px 0px #ddd;
    border-radius: 4px;
    background: #fff;
    padding-top: 8px;
    padding-left: 27px;
}

.weekly_cls ul {
  display: flex;
  padding: 0;
  margin: 0px;
}
.weekly_cls ul p{
  margin: 0px;
}
.weekly_cls ul h5{
  font-family: circular-bold;
  font-size: 16px;
}
.weekly_cls ul li:nth-child(1){
  width: 100%;
  /*text-align: center;*/
  position: relative;
}
.weekly_cls ul li:nth-child(2){
  width: 33%;
  text-align: center;
  position: relative;
}
.weekly_cls ul li:nth-child(3){
  width: 33%;
  text-align: center;
  position: relative;
}
.row_after{
  display: flex ! important;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.crd{
  box-shadow: 1px -1px 10px 0px #ddd;
}
.cd{
      font-size: 18px;
    font-family: 'circular-bold';
}
.cdinput input {
    width: 50%;
    height: 70px;
}
.cdborder{
  overflow: hidden;
  width: 100%;  
  padding: 50px 10px 15px 10px;
}
.home_chart{
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.36);
    border-radius: 10px;
    padding: 25px;
    /*margin-left: 30px;*/
    margin-top: 50px;
    margin-bottom: 50px !important;
}
.col-pad-lr-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.col-pad-lr-50 {
  padding-left: 20px;
  padding-right: 50px;
}
.cus-ver-scroll {
  height: 85vh;
  overflow-y: scroll;
}
#sidebar::-webkit-scrollbar {
  width: 0px;
}
.dropdown-container {
  margin-left: 15px;
}

/* ASO pakage */
.asopakage-outs {
  margin-left: 250px;
  padding-left: 50px;
  padding-right: 50px;
  background: #FCFCFC;
}
.asopackage {
  padding-top: 150px;
  padding-bottom: 150px;
}
.asopackage-box {
  text-align: center;
  background: #F9F9F9;
  box-shadow: 0px 0px 10px #ccc;
  padding: 30px 20px;
}
.asopackage-box h2{
  color: #4DA4E9;
}
.asopackage-box ul {
  padding-top: 30px;
  margin-left: 0px;
  padding-left: 0px;
}
.asopackage-box ul li i {
  padding-right: 8px;
}
.asopackage-box ul li {
  padding-bottom: 10px;
}
.asopackage-box button {
  background: #3BA9FF;
  color: #fff;
  display: inline-block !important;
  width: 130px;
}
.asopackage-col-2 {
  z-index: 1;
}
.asopackage-box-2 {
  transform: scale(1.2);
  background: #fff;
}

/* aso payment */
/* .asopayment-outs, .asopakage-outs {
  background: #FCFCFC;
} */
/* .asopayment-ptb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
} */
.asopayment-ptb-50 form {
  box-shadow: 0px 0px 10px #ccc;
  padding: 30px 50px;
}
.asopayment-ptb-50 form h3 {
  padding-bottom: 20px;
}
.asopayment-ptb-50 form h3 span {
  border-radius: 50%;
  border: 1px solid #12315D;
  padding: 0px 10px;
}
.asopayment-ptb-50 form .radio input {
  margin-right: 5px;
}
.asopayment-ptb-50 form .radio {
  margin: 0px 25px;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 20px;
  display: inline-block;
}
.asopyment-right {
  box-shadow: 0px 0px 10px #ccc;
  padding: 20px 30px;
}
.asopyment-right-cont {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  padding-top: 20px;
}
.asopyment-right-tol p {
  color: #293E69;
}
.asopyment-right-tol p span {
  float: right;
  color: #01B170;
}
.sidebar-header {
  margin: 8px 0px;
}
.top-sidebar {
  background: #293e69;
  /* height: 100vh; */
  /* overflow-x: hidden; */
  overflow-y: auto;
  margin-right: 35px;
  height: 100%;
  border-bottom: 1.5px solid #ddd;
}
.cus-ver-scroll::-webkit-scrollbar {
  width:0px;
}
.topbar-logo-mobl {
  margin-left: 0px !important;
  margin-right: 0px !important;
  height: 15vh;
}
.top-sidebar .sidebar-header img {
  padding: 20px;  
}
.cus-right-navbar {
  padding: 0rem !important;
}

.dash_mainout-col {
  transition: 0.5s;
}
.mobile-sidemenu span {
  width: 20px;
  height: 2px;
  background: #fff;
  display: block;
  margin: 3px 0px;
}
.mobile-sidemenu {
  display: inline-block;
  
  margin-top: 27px;
  margin-right: 30px;
  /* border: 1px solid #ccc; */
  box-shadow: 0px 0px 5px #fff;
}
.innrmobile-sidemenu {
  padding: 5px 8px;
}
#grForm {
  position: relative;
}
#b2b, #ecommerce, #retail, #acquiation {
  position: absolute;
  margin-top: -80px;
}
.loader-container.load{
    z-index: -1;
}
loader-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F8F8F8AD;
  position: fixed;
    /* display: none; */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: pointer;
}
.loader{
  left: 50%;
  top: 30%;
  z-index: 1000;
  position: absolute;
}
.profile-pic-uploader{
  width: 260px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}
.wework-cuspad {
  padding-left: 15px;
  padding-right: 15px;
}
.onlinevdombl {
  display: none;
}
.onlinerightvdobox {
  display: block;
}
.Membersp_lrnmr {
  background: #fff;
  color: #00b170;
  padding: 8px 20px;
  border-radius: 26px;
}
.Membersp_lrnmrpl-5 {
  margin-left: 10px;
}
.f_details a:hover {
  color: #fff;
}
.se_set {
  padding-top: 15px;
}
.ser_setout_head {
  margin-left: 117px;
  padding-top: 100px;
  color: #00b170;
}
.ser_setout h2{
  text-transform: capitalize;
}
.banner_content p {
  display: none;
}
.content_box h5, .content_box p {
  display: none;
}
.videosection .videosPlayer iframe  {
  height: 350px;
}
.bl-container.container .pagination {
  display: none;
}
.form-cusinl-b2 button {
  padding: 3px 22px !important;
  font-size: 15px;
  margin-top: 10px;
  border-radius: 5px;
}
.success {
    background: #2eb85c;
    font-size: 20px;
    color: #fff !important;
    position: absolute;
    left: 2%;
    bottom: 0px;
    padding: 0px 15px;
}
.dispmb1 {
  display: none;
}
.dispmb0 {
  display: inline-block;
}
.sevdispmb0{
  display: block;
}
.sevdispmb1 {
  display: none;
}
.social ul li a img {
  width: 45px;
}
.jb_title button.btn.btn-success {
  padding: 0px 15px;
}
.join_ez li {
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.card-body.row.no-gutters input.form-control {
  border: none;
}
.card-body.row.no-gutters input.form-control:focus {
  border: none;
  box-shadow: none !important;
}
.t_bar a:hover {
  color: #fcfcfc;
}
.dash_sideout-col {
  width: 25vw;
  transition: all 0.3s ease;
}
.dash_mainout-col {
  width: 75vw;
  transition: all 0.3s ease;
}
.dash_sideout-row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.dash_sidehead-col {
  width: 25vw;
  transition: all 0.3s ease;
}
.dash_mainhead-col {
  width: 75vw;
  transition: all 0.3s ease;
  border-bottom: 1.5px solid rgb(204, 204, 204);
}

.ddrop-none {
  display: none;
}
.carr_letTk .change_service.service_desc {
  padding-bottom: 0px;
}

/* Mobile device responsive */
@media only screen and (max-width: 480px) {
  /* .aso-outs, .work_outs, .agreements, .posible, .smoaboutbus-out, .smopossible-out, .other_services, .seoserv-out, .asoab_panel {
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
  } */
  .customplan_details h4{
      font-size: 15px;
  }
  .customplan_details .custom_p{
    width: 280px;
  }
  .customplan_details .cplan-body{
    width: auto;

  }
  .customplan_details .scsmdl-body{
    padding: 10px;
  }
  .apexcharts-legend-text{
  line-height: 22px;
    font-size: 9px !important;
    padding-left: 5px;
}
  .services h2 {
    font-size: 26px !important;
  }
  .contact_banners.row {
    display: none;
  }
  .text-center{
  text-align: center !important;
  margin-top: 0px;
  margin-right: 0px;
}
button.btn.btn-success{
  border-radius: 40px;
  width: auto !important;
  /* height: 50px; */
   padding: 7px 13px !important; 
  display: inline-block;
   font-size: 15px !important; 
  font-family: circular-regular,sans-serif;
  background: #01b170;
  text-transform: capitalize;
}
.crs-img{
  display: none;
}
  .loct-info1 {
    left: -18px;
  }
  .loct-info3 {
    right: -14%;
  }
  .memcontent.memvdocont {
    top: 50px;
  }
  .sub_service li ul li:last-child a {
    border-bottom: 1px solid #ddd !important;
  }
  .service_desc h1 {
    margin-top: 0px;
  }
  .ppc_buss-box1, .ppc_buss-box2 {
    width: 150px !important;
  }
  .onlcor-btn {
    margin-bottom: 25px;
  }
  .lang_select ul li {
    display: block !important;
    float: none !important;
    text-align: left !important;
  }
  .footer li:nth-child(2) {
    margin-left: 90px;
  }
  .dash_sidehead-col {
    width: 100vw !important;
  }
  .dash_mainhead-col {
    width: 100vw !important;
  }
  .dash_sideout-col {
    width: 100vw !important;
  }
  .dash_mainout-col {
    width: 100vw !important;
  }
  .col-pad-lr-50 {
    padding-left: 0;
    padding-right: 0;
  }
  .dash_sideout-row {
    position: relative;
    margin-left: 0px;
    margin-right: 0px;  
  }
  .dash_sideout-col {
    position: absolute !important; 
    top: 0px;
    display: none;
  }
  section.service_client {
    margin: 30px 0px;
  }
  section.our_servces .slick-list {
    padding: 5px 0px;
    padding-bottom: 30px;
  }
  .topbar-logo-mobl {
    height: auto;
  }
  section.our_servces .slick-dots {
    bottom: 10px;
  }
  .social ul{
    margin: 30px auto 50px auto; 
    width: 40%;
  }
  .sevdispmb0 {
    display: none !important;
  }
  .sevdispmb1 {
    display: block !important;
    width: 100px;
    margin-bottom: 20px;
  }
  section.we_work {
    margin: 20px 0px;
  }
  .service_desc h1 {
    margin-bottom: 10px;
  }
  .card-body.row.no-gutters input.form-control {
    border: none;
  }
  .hovered_content h3 {
    opacity: 1 !important;
  }
  .online_content button.btn.btn-success {
    margin-top: 0px;
  }
  .ser_setout {
    z-index: 0;
  }
  .dispmb1 {
    display: inline-block;
  }
  .dispmb0 {
    display: none;
  }
  /* .work-banner-container .slider-banner-container .slider-content img { */
    /*width: 100%;*/
  /* } */
  .work-banner-container .slider-banner-container .slider-content p {
    display: none;
  }
  .solution {
    margin-top: 0px;
  }
  .form-group.form-cusinl-b1 {
    float: left;
    margin-bottom: 0px;
  }
  .f_details h4 {
    margin-bottom: 25px;
  }
  .memcontent .termsul {
    display: none;
  }
  .memcontent {
    margin: 25px 0px 0px;
  }
  .safty_banner {
    height: 300px;
  }
  .wt-belve {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .feature-tabs ul li h2 {
    font-size: 14px;
  }
  .feature-tabs ul li span {
    font-size: 12px;
  }
  .feature-tabs ul li {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .gr_form h1 {
    margin: 50px 0px 30px 0px;
    font-size: 16px;
  }
  .form-cusinl-b2 button {
    padding: 3px 22px !important;
    font-size: 15px;
    margin-top: 10px;
    border-radius: 5px;
    float:  right;
  }
  .social ul li {
    margin: 0px 3px;
  }  
  .b_content h3 {
    margin-bottom: 0px;
  }
  .faq_bodysection {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .faq_banner {
    height: 300px;
  }
  .faqcontent {
    margin: 0px;
  }
  section.whyjoin h1 {
    margin: 0px 0px 50px;
  }
  .whyjoin li {
    margin-bottom: 20px;
  }
  .whyjoin {
    padding-bottom: 0px !important;
  }
  .join_ez h1 {
    margin: 50px 0px 50px;
  }
  section.social {
    margin: 0px 0px 50px;
  }
  .careerPage .contact_banner {
    height: 300px;
  }
  .imgb2b{
    margin: 0px;
  }
  .imgb2b p {
    margin-top: 20px;
  }
  .sm_set {
    margin-bottom: 30px;
  }
  .videosection .videosPlayer iframe  {
    height: 250px;
  }
  #b2b .slider-content p {
    display: none;
  }
  #b2b .slider-content img {
    width: 100%;
  }
  #ecommerce .slider-content p {
    display: none;
  }
  #ecommerce .slider-content img {
    width: 100%;
  }
  #retail .slider-content p {
    display: none;
  }
  #retail .slider-content img {
    width: 100%;
  }
  #acquiation .slider-content p {
    display: none;
  }
  #acquiation .slider-content img {
    width: 100%;
  }
  .register-container .register_image img, .register-container .support_image img {
    display: none;
  }
  .register-container .find_image img, .register-container .growing_image img {
    display: none;
  }
  .register-container .register_icon, .register-container .support_icon {
    top: 30px;
    right: 15vw;
  }
  .register-container .find_icon, .register-container .grow_icon {
    top: 30px;
    left: 15vw;
  }

  .register-container .register_icon::before {
    position: absolute;
    content: "\f063";
    font-family: 'fontawesome';
    bottom: 50%;
    right: -65px;
    transform: rotate(-90deg);
    color: #01b170;
  }
  .register-container .find_icon::before {
    position: absolute;
    content: "\f063";
    font-family: 'fontawesome';
    bottom: -52%;
    right: 18px;
    transform: rotate(0deg);
    color: #01b170;
  }
  .register-container .support_icon::before {
    position: absolute;
    content: "\f063";
    font-family: 'fontawesome';
    bottom: 50%;
    right: -65px;
    transform: rotate(90deg);
    color: #01b170;
  }
  /* .register-container .register_icon::before, .register-container .support_icon::before {
    position: absolute;
    content: "\f063";
    font-family: 'fontawesome';
    bottom: -15px;
    left: -55px;
    transform: rotate(40deg);
    color: #01b170;
  }
  .register-container .find_icon::before {
    position: absolute;
    content: "\f063";
    font-family: 'fontawesome';
    bottom: -30px;
    right: -45px;
    transform: rotate(315deg);
    color: #01b170;
  } */
  .register_image.details-image, .support_image.details-image, .find_image.details-image, .growing_image.details-image {
    height: 120px;
  }
  .details-box {
    padding: 35px 0;
  }
  .details-box img {
    width: 200px;
  }
  
  .cusbord-bot {
    border-bottom: 1px solid #ccc;
  }
  .register-container .row-contentmt-50 {
    margin-top: 50px;
  }
  .register-container {
    padding-top: 20px;
  }

  .content_box .para h5, .content_box .para p {
    display: none;
  }
  .contact_banner .banner_content p {
    display: none;
  }
  .contact_banners .para p {
    display: block !important;
  }
  .contact_banners .para .cn {
    display: block !important;
  }
  .contact_banners .para button.btn.btn-success {
    height: auto;
    font-size: 16px;
  }
  .contact_banners .para .btn.btn-success img {
     width: 16px;
  }
  .set_arrow > a:after {
    top: 10px;
  }
  .banner_content h3 {
    margin-bottom: 0;
  }
  .ser_setout_head {
    padding-left: 20px;
    margin-top: 50px;
    margin-left: 0px;
    padding-top: 0px;
  }
  section.latest_blog .mbl_view {
    padding-left: 10px;
    padding-right: 10px;
  }
  .hovered_content {
    opacity: 1;
  }
  .hovered_content>div {
    width: 95%;
  }
  .work_in {
    height: 200px;
    margin-bottom: 20px;
  }
  .work_in h3 {
    opacity: 0;
  }
  .onlinevdombl {
    display: block;
  }
  .onlinerightvdobox {
    display: none;
  }
  section.we_work {
    margin-bottom: 0px;
  }
  .services .slick-track {
    padding: 15px 0px;
    padding-bottom: 20px;
  }
  section.services {
    padding: 0;
  }
  .para p, .para h5 {
    font-size: 14px;
  }
  .c_set {
    position: relative;
    min-height: 380px;
    margin: 0px;
  }
  .mem_set {
    margin: 0;
    padding: 20px;
    display: none;
  }
  .aso-perform ul {
    width: 100%;
    padding-left: 0px;
  }
  .asoeasy {
    padding-bottom: 50px;
  }
  /* .side_out {
    position: absolute !important;
    width: 0px;
  } */
  .top-sidebar {
    margin-right: 0px;
  }
  .dash_sideout-row  {
    margin-right: 0 !important;
  }
  
  #sidebar {
    margin-right: 0px;
  }
  .mobl-vw-d-0 {
    display: none;
  }

  #root {
    overflow-x: hidden;
  }
  .inx-banner-limg {
    display: none;
  }
  .t_bar ul {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .t_bar li {
    margin-bottom: 5px;
    display: inline-block;
  }
  .t_bar ul li:nth-child(3) {
    text-align: center;
    margin-top: 5px;
    float: right;
  }
  .t_bar ul li:nth-child(2) {
    text-align: right;
    float: right;
  }
  section.service_desc {
    padding-bottom: 0px;
  }
  .se_set {
    padding-top: 15px !important;
  }
  section.change_service.service_desc {
    padding-bottom: 50px;
    padding-top: 0px;
  }
  .service_desc .seo_content p {
    margin-bottom: 0px;
    text-align: left;
  }
 /* .react-responsive-modal-modal {
    margin: auto 0 !important;
    min-height: 200px;
  }*/
  .react-responsive-modal-modal iframe {
    height: 225px;
  }  
  .sub_service li ul {
    float: none;
  }
  .mobile-contct-pgdns {
    display: block !important;
  }
  .mobile-contct-pgdns .content_box {
    width: 100%;
    padding: 30px 15px;
  }
  .mobile-contct-pgdns .cnform {
    padding: 15px 20px;
  }
  .desc-bannerform-dsp {
    display: none !important;
  }
  .contact_banners {
    background-size: auto;
    background-position: center left;
    padding: 10px 0px;
  }
  .cusmargint-px40 {
    margin-top: 10px !important;
  }
  .contpg-banerlogo-i {
    width: 150px !important;
  }
  .contpg-banerlogo-i2 {
    width: 160px !important;
  }
  .contact_banners .banner_content {
    min-height: 100px;
  }
  .decktp.banner_content {
    padding: 25px 30px;
  }
  .toolmaphover {
    margin-left: 55px;
  }
  .ser_setout div[tabindex] {
    padding: 0px 15px 0px 5px;
  }
  .content {
    height: 35vw;
  }
  .contmap_info {
    width: 200px;
  }
  .content .tooltiptext {
    width: 325px;
    margin-top: -110px;
  }
  .bannertopcontain {
    margin-bottom: 30px;
  }
  .bottome_set {
    height: 150px;
  }
  
 
p.succes {
  text-align: center;
  margin: 0px 0px 0px;
  color: green;
  font-size: 14px;
}
p.succes.error{
  color: red;
}
p.succes.suc{
  color: green;
}

  .react-responsive-modal-modal h1 {
    text-align: center;
    color: #000000e0;
    font-family: circular-bold;
    font-size: 18px;
    line-height: 26px;
    margin: 10px 0px 22px;
}
  .get_started {
    margin-top: 30px;
  }
  li.nav-item {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .mbl-footercontact {
    display: inline-block !important;
  }
  .dsck-footercontact {
    display: none !important;
  }
  .extralink_headi {
    display: block !important;
  }
  .f_services.show, .f_extra.show, .f_links.show {
    padding-left: 20px;
    margin-top: -15px;
  }
  .t_bar ul li:nth-child(2) {
    display: none;
  }
  .details-box li {
    line-height: 1.8;
  }
  .details-box li:before {
    top: 1vw;
  }
  .blog-dates {
    padding: 1px 4px;
  }
  .br_more {
    padding-top: 0px;
  }
  .jb_title button.btn.btn-success {
    width: 110px;
    font-size: 15px;
    padding: 5px 15px;
  }
  .whycct-mbl0 {
    display: none;
  }
  .member_img {
    height: 200px;
  }
  .contact-map-banner {
    overflow-x: hidden;
  }
  .about-contsec .contact_banner {
    height: 250px;
  }
  .rlpost_list h5 {
    margin-top: 0;
  } 
  .rlpost_list {
    padding-bottom: 130px;
  }
  .rlpost_list .rl_posts div {
    width: 350px;
    margin: 0 auto;
  }
  .rlpost_list .rl_posts img {
    width: 350px;
  }
}

.our-services-title {
  font-size: 36px;
}

/* Tab device responsive */
@media screen and (max-width: 992px) and (min-width: 480px) {
  .our-services-title {
    font-size: 26px !important;
  }
  .onlinevdombl {
    display: block !important;
  }
  .onlinerightvdobox {
    display: none !important;
  }
  .rlpost_list h5 {
    margin-top: 0;
  } 
  .rlpost_list {
    padding-bottom: 130px;
  }
  .rlpost_list .rl_posts div {
    width: 350px;
    margin: 0 auto;
  }
  .rlpost_list .rl_posts img {
    width: 350px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 480px) {
  .onlinevdombl {
    display: none;
  }
  .onlinerightvdobox {
    display: block;
  }
  .contact-map-banner {
    overflow-x: hidden;
  }
  .contact_banners .content_box .para {
    padding-left: 15px;
  }
  .banner_content .content_box .param {
    margin-left: -75px;
  }
  .se_set {
    width: 90%;
  }
  .slick-slide{ 
    padding: 0px 1px;
  }
  section.service_desc {
    top: -110px;
    padding-bottom: 0px;
  }
  section.service_client {
    margin: 50px 0px 30px;
  }
  section.change_service.service_desc {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  section.our_servces {
    margin-top: -75px;
  }
  .service_desc .seo_content p {
    margin-bottom: 10px;
  }
  .service_desc h1 {
    margin-bottom: 30px;  
  }
  .ser_setout_head {
    margin-left: 20px;
    padding-top: 75px;
  }
  .work_in {
    height: 200px;
  }
  .work_in h3 {
    opacity: 0;
  }
  .hovered_content {
    opacity: 1;
  }
  .hovered_content>div {
    width: 95%;
  }
  .hovered_content h3 {
    opacity: 1;
  }
  .member_img { 
    height: 300px;
  }
  .onlinerightvdobox {
    display: none;
  }
  .onlinevdombl {
    display: block;
    float: right;
    position: relative;
  }
  .online_content p  {
    width: 50%;
    display: inline-block;
  }
  .v_set {
    height: 200px;
  }
  .br_set {
    float: none;
  }
  .br_set li img {
    display: block;
    text-align: center;
    margin: auto;
  }
  .online_course .row {
    margin-top: 40px;
  }
  .online_course h1 {
    margin-bottom: 0px;
  }
  .extra_links ul  {
    margin-bottom: 10px;
    text-align: center;
  }
  .extra_links ul li {
    float: none;
    display: inline-block;
    width: auto;
    padding: 0px 8px;
    border-right: 1px solid #fff;
    line-height: 10px;
  }
  .extra_links ul li:last-child {
    border-right: none;
  }
  .back_video {
    height: 110%;
  } 
  .mem_set {
    display: none;
  }
  li.nav-item {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .inx-banner-limg {
    display: none;
  }
  .navbar-expand-sm .navbar-toggler {
    display: block !important;
    position: absolute;
    right: 40px;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: column !important;
    width: 100%;
  }
  .navbar-expand-sm .navbar-collapse {
    position: absolute;
    width: 100% !important;
    top: 92px;
    left: 0px;
    background: #fff;
  }
  .t_bar ul {
    width: auto;
  }
  .t_bar li {
    margin: 0px 10px;
  }
  .set_arrow > a:after {
    top: 10px;
  }
  .container-fluid.banner {
    min-height: 575px !important;
  }
  .sub_service li ul {
    float: none;
  }
  .svg_out {
    margin: 0px auto 0px;
  }
  .slider_out {
    width: 530px;
    height: 170px;
    bottom: 58px;   
  }
  .cl_set {
    position: relative;
  }
  .slick-slider.testimonial_slider.slick-initialized{
    top: 0;
  }
  .testimonial_set {
    padding: 25px;
  }
  .onlinerightvdobox {
    margin-top: 50px;
  }
  .c_set{
    position: relative;
  }
  .mem_set {
    margin: 20px 0px 20px;
  }
  .mem_set h1 br {
    display: none;
  }
  .mem_set {
    width: 100%;
  }
  .set_arrow > a:after {
    top: 8px;
    right: 20px;
  }
  .contact_banners {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .vluebox-left {
    padding-left: 30px !important;
  }
  .vluebox-left img {
    top: -75px;
    right: -50px;
  }
  .sec-title {
    margin: 70px 0px 30px 0px;
  }
  .about-contsec .contact_banner {
    height: 300px;
  }
}

/* Desktop device responsive */
@media screen and (max-width: 1024px) and (min-width: 992px) {
  .c_set {
    min-height: 270px;
  }
  .onlinevdombl {
    display: none !important;
  }
  .onlinerightvdobox {
    display: block !important;
  }
  a.btn.btn-success.login_set {
    padding: 5px 0px;
    width: 100px;
  }
  .extra_links ul li {
    padding: 0px 25px;
  }
  .vluebox-left img {
    top: -85px;
    right: -50px;
  }
  .sec-title {
    margin: 70px 0px 30px 0px;
  }
  ul.sub_service, .sub_membership, .course_list, .user_list {
    left: 20px;
    top: 5px;
    width: 95% !important;
    position: relative;
  }
  .extra_links {
    max-width: 100%;
  }
  .c_set {
    left: -145px;
    padding: 10px 0px;
    width: 70vw;
    bottom: -50px;
    padding-right: 60px;
    min-height: 240px;
  }
  .c_set h3 {
    margin-top: 5px;
  }
  .v_set.video3 {
    left: -100px;
    top: 80px;
  }
  .v_set.video2 {
    right: 0;
    top: -10%;
  }
  .v_set.video1 {
    right: 0;
    bottom: 106px;
  }
  .contact_banners {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .banner_content .content_box .param{
    margin-left: 0px;
  }
  .contact_banners {
    padding: 50px 0px;
  }
  .content_box {
    margin-top: 0px;
  }
  .contmap_info{    
    margin: 50px auto 10px auto;
  }
  .extra_links {
    max-width: 100%;
  }
  .services h2 {
    margin-bottom: 30px;
  }
}

/* Desktop device responsive */
@media only screen and (min-width: 1024px) {
  .navbar-nav .nav-item.set_arrow:hover .sub_service, .sub_service:hover {
    display: block;
  }
  .navbar-nav .nav-item.set_arrow:hover .members_list, .members_list:hover {
    display: block;
  }
  .navbar-nav .nav-item.set_arrow:hover .course_list, .course_list:hover {
    display: block;
  }
  .navbar-nav .nav-item.set_arrow:hover .user_list, .user_list:hover {
    display: block;
  }
  .navbar-nav .nav-item.set_arrow:hover .tools_list, .tools_list:hover {
    display: block;
  }  
}
.extralink_headi {
  display: none;
}
.mbl-footercontact {
  display: none;
}
.dsck-footercontact {
  display: inline-block;
}
.contpg-banerlogo-i {
  width: 150px;
}
.contpg-banerlogo-i2 {
  width: 190px;
}
.cusmargint-px40 {
  margin-top: 40px;
}
.desc-bannerform-dsp {
  display: block;
}
.tr_ppc_inner-box {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAACrCAYAAACE5WWRAAAABHNCS…n2MddlYGcniNbWdCf86U8AU6eGAcFYFODPyblHHE1kw/8HqHcRkDmLJA4AAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-size: cover;
    width: 150px;
    height: 180px;
    float: left;
    background-position: center;
    text-align: center;
    box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 27%);
    padding: 30px 0px;
    border: 1px solid #dddddd4d;
}

.ppc_buss-box1 {
  float: right !important;
  width: 200px;
  height: 200px;
}
.ppc_buss-box2 {
  float: left !important;
  width: 200px;
  height: 200px;
}
.ppc_buss-box1 h5{
  font-size: 24px;
}
.ppc_buss-box2 h5{
  font-size: 24px;
}
.ppc_buss-box1 p{
  font-size: 14px! important;
}
.ppc_buss-box2 p{
  font-size: 14px! important;
}
.row.tr_seo {
  margin-left: 0px;
}
.react-responsive-modal-modal {
  position: relative;
}

body.light-mode {
  background-color: #fff;
  color: #333;
  transition: background-color 0.3s ease;
}
body.dark-mode{
  background-color: #293e69 ! important;
  color: #dfdfdf;
}

body.dark-mode h1, body.dark-mode h2, body.dark-mode h3, body.dark-mode h4, body.dark-mode h5, body.dark-mode h6, body.dark-mode p, body.dark-mode a, body.dark-mode span, body.dark-mode h6 {
  color: #dfdfdf;
}

 
  .invoice h3 {
    text-align: center;
    text-transform: capitalize;
    /*background: #f2f9fe;*/
    padding: 22px 0px;
    font-family: OpanSansebold;
    font-family: circular-bold;
    color: #000;
    font-size: 20px;
}

  /* .dark-mode-toggle > button {
    color: #999;
    &:last-child {
      color: lightblue;
    }
  } */

body.dark-mode .content code {
  background-color: #1b2938;
}

body.dark-mode .content a {
  color: #ee09;
}

body.dark-mode .content button {
  color: #ee09;
}

/* .dark-mode-toggle {
  display: flex;
  margin: 0 auto;
  & > button {
    font-size: 1.2em;
    background: none;
    border: none;
    color: #ffe600;
    cursor: pointer;
    transition: color 0.3s ease;
    &:last-child {
      color: #666;
    }

    &:focus {
      outline: none;
    }
  }
} */

.toggle-control {
  position: relative;
  padding: 0 4px;
  display: flex;
  align-items: center;
}



/* .react-responsive-modal-modal::after {
  content: "X";
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 800;
  padding: 10px 15px;
  background: #fff;
  color: #bd3333;
} */

button.react-responsive-modal-closeButton svg { 
  background: #fff;
  fill: #a7a1a1;
}
button.react-responsive-modal-closeButton {
  color: #f00;
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px;
  background: #fff;
}
.mobile-contct-pgdns {
  display: none;
}
.site_dinamic_header {
  height: 156px;
}
.sticky {
  position: fixed;
  top: 0;
  z-index: 100; /* this is optional and should be different for every project */
}
.sticky-wrapper {
  position: relative;
  z-index: 99999;
}
.sticky-wrapper .site_header {
  position: absolute;
  width: 100%;
}

.sticky-wrapper.sticky .sticky-inner {
  display: block !important;
  opacity: 1;
  transition: 6s;
}
.sticky .sticky-inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.sticky-wrapper.sticky .navbar {
  padding: 0px;
  padding-right: 25px;
}
.smo-record-box .apexcharts-legend {
  position: absolute;
  top: 55%;
  /*left: 52%;*/
  transform: translate(-50%,-50%);
}
.font-weight-bold {
  font-weight: 400 !important;
}
.footsocicn-s1, .footsocicn-s2 {
  display: block;
}
footer {
  position: relative;
}
.footsocicn-s1 {
  position: fixed;
  right: 20px;
  bottom: 70px;
  z-index: 99;
}
.footsocicn-s2 {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 99;
}
.footsocicn-s1 img {
  width: 55px;
}
.footsocicn-s2 img {
  width: 50px;
}
.scroll-to-top {
  display: inline-block;
  position: fixed;
  right: 26px;
  bottom: 80px;
  cursor: pointer;
}
.scroll-to-top img {
  width: 30px;
  margin-right: 5px;
}
.grivence {
  padding-bottom: 80px;
}
.form-cusinl-b1, .form-cusinl-b2 {
  display: inline-block;
}
.form-cusinl-b2 {
  margin-left: 80px;
  margin-bottom: 0px !important;
}
.form-cusinl-b2 button {
  padding: 8px 30px;
}
.form-control:focus {
  /*border-color: #0c1a41 !important;*/
  box-shadow: 0 0 0 0.2rem rgba(12,26,65,0.3) !important;
}
.formstylbox {
  box-shadow: 0px 0px 10px #ccc;
  /* display: inline-block; */
  max-width: 450px;
  padding: 30px;
  margin: 0px auto;
}
#sel1 option {
  color: #0064b0;
}
#sel1 option:hover {
  color: #fff;
}
li.nav-item {
  padding-top: 15px;
  padding-bottom: 15px;
}
.rgtrdt-bth {
  position: absolute;
  left: 50px;
  top: 50px;
  padding: 7px 13px 8px 13px;
  background: #00b170;
  color: #fff;
  border-radius: 50%;
  z-index: 1;
}
.memcontent .termsul li a {
  color: #3c4b64;
}
.react-responsive-modal-root {   
    z-index: 99999 !important;
}
.css-1pahdxg-control:hover{
  border-color: #d8dbe0 !important;
  box-shadow: 0 0 0 1px #d8dbe0 !important;
}
.back_video .video-react.video-react-fluid {
  width:  100% !important;
}
/* .react-responsive-modal-container .react-responsive-modal-modal {
  padding: 0px;
  padding-bottom: 0px;
  margin: 0;
  margin-bottom: 0px;
  margin-top: 100px !important;
  max-width: 800px !important;
}
.react-responsive-modal-container .react-responsive-modal-modal iframe {
  margin-bottom: -7px;
  height: 450px;
} */
.modal-video-body {
  max-width: 850px !important;
}
.modal-video-inner {
  padding-top: 110px;
}

/* Business Verticals */
.bsnvrt_header_sec {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 30px;
}
.bsnvrt_header_sec h2 {
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #333;
}
.bsnvrt_header_sec p {
  margin-bottom: 0px;
}
.bsnvrt_category_sec {
  padding-top: 30px;
}
.bsnvrt_catboxcol_gybox .bsnvrt_catboxcol_gy:nth-child(odd) {
  background: #e6e6e6;
  padding: 20px 20px 40px 20px;
}
.bsnvrt_catboxcol_gybox .bsnvrt_catboxcol_gy:nth-child(even) {
  background: #fff;
  padding: 20px 20px 40px 20px;
}
.bsnvrt_catboxcol_wtbox .bsnvrt_catboxcol_wt:nth-child(odd) {
  background: #fff;
  padding: 40px 20px 20px 20px;
}
.bsnvrt_catboxcol_wtbox .bsnvrt_catboxcol_wt:nth-child(even) {
  background: #e6e6e6;
  padding: 40px 20px 20px 20px;
}

.bsnvrt_catboxcol_gybox .bsnvrt_cat_box {
  margin-left: auto;
}
.bsnvrt_catboxcol_wtbox .bsnvrt_cat_box {
  margin-right: auto;
}
/* .bsnvrt_catboxcol_wtbox .bsnvrt_catboxcol_gy:nth-child(odd) {
  background: #fff;
  padding: 50px 20px 10px 30px;
}
.bsnvrt_catboxcol_gybox .bsnvrt_catboxcol_wt:nth-child(even) {
  background: #fff;
  padding: 50px 20px 10px 30px;
}
.bsnvrt_catboxcol_wtbox .bsnvrt_catboxcol_wt:nth-child(even) {
  background: #e6e6e6;
  padding: 30px 20px;
} */
/* .cus-ml-a .bsnvrt_cat_box {
  margin-left: auto;
}
.cus-mr-a .bsnvrt_cat_box {
  margin-right: auto;
} */
.bsnvrt_cat_box {
  background: #fff;
  border: 1px solid #0085bf;
  padding: 20px;
  max-width: 500px;  
  position: relative;
  overflow: hidden;
}
.bsnvrt_cat_box .busvertlbi {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100px;
}
.bsnvrt_cat_box .busvertlbicon {
  position: absolute;
  bottom: 2px;
  right: 19px;
  color: #fff;
  font-size: 24px;
}
.bsnvrt_catbox_rimg {
  width: 100%;
}
.bsnvrt_cat_overlay {
  position: absolute;
  top: 100%;
  left: 100%;
  width: 100%;
  height: 100%;
  background: #0085bf;
  opacity: 0;
  transition: 0.5s;
}
.busvert_triang, .busvert_triang svg {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100px;
  height: auto;
  z-index: 1;
}
.bsnvrt_cat_overlay .bvcatlogo_bx {
  display: inline-block;
  margin: 5px;
}
.bsnvrt_cat_overlay .bsnvrtcatoverl_logo {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.busvert_triang span {
  transition: 0.3s;
  position: absolute;
  bottom: 1px;
  right: 17px;
  color: #fff;
  z-index: 1;
  font-size: 24px;
}
/* .busvert_triang:hover span {
  color: #fff;
  transform: rotate(45deg);
} */

.bsnvrt_cat_box.active .bsnvrt_cat_overlay {
  opacity: 1;
  top: 0;
  left: 0;
}
.bsnvrt_cat_box.active .busvert_triang span {
  color: rgb(0, 133, 191);
  transform: rotate(45deg);
}
.bsnvrt_cat_box.active .busvert_triang {
  color: #fff;
}
.bsnvrt_cat_box .busvert_triang svg, .bsnvrt_cat_box .busvert_triang span {
  cursor: pointer;
}
.bsnvrt_cat_box.active .busvert_triang svg #Path_14440 {
  fill: #fff;
}
.bsnvrtdwnarw img {
  width: 40px;
  display: block;
  margin: 0px auto;
}
.bsnvrtclpg .work_banner {
  height: 400px;
}
/* error 404 */
.error-404-page {
  width: 100%;
  height: calc(100vh - 156px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-404-page .error-content {
  text-align: center;
}

.error-404-page .error-content h2 {
  padding: 60px 0 48px;
}

.error-404-page .error-content a {
  color: #fff;
  background-color: #293e69;
  padding: 12px 32px;
  border-radius: 40px;
}


.pgnactvpg {
  color: #f00;
}
.comnpaginationsdiv {
  text-align: center;
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 10px;
}
.comnpaginationsul {
  display: inline-block;
  margin: auto;
}
.comnpaginationsul li {
  display: inline-block;
  background: #143059;
  margin: 0px 5px;
  padding: 5px 12px;
  border-radius: 50%;
  color: #fff;
}
.comnpaginationsul .pgnactvpg {
  padding: 5px 13px;
  font-size: 18px;
}
.addmore-invoice {
  text-align: center;
}
.react-date-picker {
  width: 100%;
}
.react-date-picker__inputGroup__input {
  min-height: 20px !important;
  display: inline-block !important;
  width: 10px;
}
.popup .pop-content .popup-body form .form-field input {
  height: 20px !important;
  /* border: none !important; */
}
.emptyerrs {
  color: #f00;
}
.nowrap-txt {
  white-space: nowrap;
}
.botclosebtn {
  float: right;
}
.addmore-invoice {
  background: #fff;
  padding-bottom: 20px;
  margin-bottom: 0px;
}
.addmore-invoice span {
  border: #3885c1;
  color: #3885c1;
  border-radius: 50%;
  border: 1px solid #3885c1;
  padding: 5px 10px 6px 10px;
  font-size: 18px;
}
.testresults-showall-btn {
  margin-top: 30px;
  margin-bottom: 5px;
}
.testresults-showall-btn span{
  cursor: pointer;
  color: #000;
  font-size: 16px;
  background: #00b170;
  padding: 12px 15px;
  margin-bottom: 15px;
  float: left;
  border-radius: 40px;
  color: #fff;
}



@media screen and (max-width: 576px) {

  .signup-form .checkspan {
    margin-right: 0px;
  }
  .mbl-aligncenter {
    text-align: center;
    margin-top: 10px;
  }
  .side-menu .side-menu-top ul li .nav-link:hover .nav-sub-menu {
    position: relative;
    top: 0;
    right: 0;
    width: auto;
  }
  .side-menu .side-menu-top ul li .nav-link .nav-sub-menu {
    z-index: 1;
  }
  .d_review {
    width: 280px !important;
        min-height: 350px;
  }
  .dash_page_bg.d-testi {
    margin-top: 155px;
  }
}

.water-mark-logo {
  background-image: url('./images/water-mark-logo.png');
  background-size: 800px 350px;
  background-repeat: no-repeat;
  background-position: center;
}

kendo-pdf-document .water-mark {
  background-image: url('./images/ezio-audit.png');
  background-size: 500px 520px;
  background-repeat: no-repeat;
  background-position: center;
}

.scsmdl-container1{
  height: 350px;
  width: 450px;
}
.scsmdl-container1-min-width{
  height: auto;
  width: 480px;
}

.scsmdl-container{
  height: 350px;
  width: 700px;
}

.scsmdl-container2{
  height: 350px;
  width: 500px;
}

.scsmdl-container-min-width{
  height: auto;
  width: 480px;
}
.scsmdl-header{
  background-color: #143059;
  width: 100%;
  height: 80px;
}
.scsmdl-header p{
  display: inline-block;
  color: white;
  padding: 25px 30px;
  font-size: 17px;
  font-weight: 600;
}
.scsmdl-closeimg{
  float: right;
  border-radius: 50%;
  padding: 20px;
  cursor: pointer;
  width: 70px;
}
.scsmdl-successimg{
display: block;
margin: 20px 205px auto 215px;
}
.scsmdl-body p{
padding: 15px 60px;
/* border-bottom: solid 1px #999; */
margin-bottom: 0px;
}
.scsmdl-para{
  padding: 15px 20px;
border-bottom: solid 1px #999;
margin-bottom: 0px;
text-align: center;
}
.scsmdl-closebtn{
  border-radius: 3px;
  border: solid black 1px;
  background-color: white;
  color: #000;
  padding: 5px 15px;
  float: right;
  margin: 17px;
}
@media(max-width:500px){
  .scsmdl-container{
    width: 320px;
  }
  .scsmdl-container2{
    width: 320px;
  }
  .scsmdl-container-min-width{
    width: 320px;
  }
  .scsmdl-container1{
    width: 320px;
  }
  .scsmdl-container1-min-width{
    width: 320px;
  }
  .scsmdl-closeimg{   
    padding: 20px;
  }
  .scsmdl-successimg{
   margin: 20px 105px auto 125px;
    }
}
element.style {
    margin-left: 0px;
}

.s_detail .see-all-notif-btn {
  min-height: 40px;
    padding: 13px;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    border: none;
    outline: none;
    max-width: 130px;
    background: #fba919;
    bottom: 3rem;
    color: #fff;
}
/* login popup */
.login-popup-con{
  width: 80%;
  margin: 30px auto;
}
.login-popup-con img{
  width: 65px;
  height: 65px;
  display: block;
  margin: auto;
}
.login-popup-con h4{
  text-align: center;
  font-weight: 600;
  margin-top: 5px;
}
.login-popup-con input[type="text"]{
  padding: 8px 10px;
  margin:0px 0 20px 0 !important;
}
.login-popup-con div{
  display: flex;
  flex-direction: column;
}
.login-popup-con label span{
  margin-left: 15px;
  color: grey;
}
.login-popup-con button{

  height: 40px;
 min-width: 40px  ;
      display: flex;
      margin: 20px auto;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      border: none;
      outline: none;
      width: 150px  ;
      font-family: circular-bold, "HelveticaNeue", "Arial", sans-serif;
      background: #00b170;
      color: #ffffff;
      margin-top: 1 rem;
}
.login-popup-con p{
  color: grey;
  text-align: center;
}
.login-popup-con p span{
  color: #438FFF;
}